import React,{useEffect,useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';
function NotificationModal(props) {
  // console.log(props.NotificationData);
//date conversion
function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  const handleCheckboxChange = (id,date,nocop) => {
    const NotificationData={did:id,reqdate:date,number:nocop}
    axios.put(`${BaseUrl}/Updatestview`,NotificationData)
      .then(response => {
        props.toggleIsNotification()
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <Modal show={props.showModal} onHide={props.handleClose} size="lg" centered>
    <Modal.Header closeButton >
      <Modal.Title style={{ textAlign: 'center', width: '100%' }} className='NotifictionHeading'>Unissued Book list(After <span style={{color:'red'}}>48hrs</span>)</Modal.Title>
    </Modal.Header>
    <Modal.Body >
    <div className='row my-table-container'>
    <div className="col-12 ">
    <table className="table table-bordered table-responsive ">
  <thead style={{ background: "rgba(62, 148, 140, 1)" }}>
    <tr>
    <th >Requsted Date</th>
    <th >Member Code</th>
      <th >Dealer</th>
      <th >Books requested</th>
      <th >District</th>
      <th >Mark as read</th>
    </tr>
  </thead>
  <tbody>
  {Array.isArray(props.NotificationData) && props.NotificationData.length > 0 ? (
  props.NotificationData.map((item, index) => (<tr key={index}>
     <td  data-label="Requsted Date" style={{color:'red'}}>{formatDate(item.RequestDate)}</td>
     <td  data-label="Member Code">{item.Dcode}</td>
        <td data-label="Member">{item.Dealer}</td>
        <td data-label="Books requested">{item.NoOfCoupons}</td>
        <td  data-label="District">{item.Name}</td>
        <td data-label="Mark as read">  <input type="checkbox" onChange={() => handleCheckboxChange(item.DealerId,item.RequestDate,item.NoOfCoupons)}/></td>
      </tr>))) : (
              <tr>
                <td className="text-center" colSpan="6">
                  No notification data available.
                </td>
              </tr>
            )
      }
  
  </tbody>
</table>
</div>
</div>
    </Modal.Body>
  </Modal>
  
  );
}

export default NotificationModal;
