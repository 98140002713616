import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import { BaseUrl } from '../Constants/BaseUrl';
import MemberCouponCountState from './MemberCouponCountState';
function CouponSalesReportState(props) {
  const [totalcoupons,settotalcoupons]=useState(0)
  const SaledCoupons = (totalcoupons-(props.CouponData.length))
  const progressPercentage1 = Math.min((SaledCoupons / totalcoupons) * 100, 100).toFixed(2);
  ////

  const [DistrictWisedata, setDistrictWisedata] = useState([]);
    // Function to get today's date in the format "YYYY-MM-DD"
    function getTodayDate() {
        return new Date().toISOString().split('T')[0];
      }
     
  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;

    const startOfMonth = new Date(year, month - 1, 1);
    const endOfMonth = new Date(year, month, 0);

    const formattedStartDate = '2023-07-01'
    const formattedEndDate = endOfMonth.toISOString().substring(0, 10);
    axios
    .get(BaseUrl+ '/getdistrictcount', { params: { sdate: formattedStartDate, edate: formattedEndDate } })
    .then((response) => {
      if (response.data) {
       console.log(response.data)
          setDistrictWisedata(response.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });


    axios.get(BaseUrl+'/getinitsettings')
    .then((res)=>{
      if(res.data)
      {
        console.log(res.data[0].TotalCoupons)
     settotalcoupons(res.data[0].TotalCoupons)
      }
    })
  }, []);
  // Data for PieChart
  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#FFBB28",
    "#FF8042",
    "#AF19FF",
    "#00C49F",
    "#FF5733",
    "#B7F900",
    "#FF34B3",
    "#0078FF",
    "#C200FB",
    "#FFD300",
    "#FF1E00",
    "#00F3B2"
  ];
  
  const getTotalCount = () => {
    let totalCount = 0;
    DistrictWisedata.forEach((districtData) => {
      totalCount += districtData.TotalCount;
    });
    return totalCount;
  };
  
  // Convert DistrictWisedata into the format expected by Recharts PieChart
  const pieData = DistrictWisedata.map((districtData, index) => ({
    name: districtData.DistrictName,
    value: (districtData.TotalCount / getTotalCount()) * 100, // Convert to percentage
  }));

  

  // Custom Tooltip for PieChart
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc"
          }}
        >
          <label>{`${payload[0].name} : ${payload[0].value}%`}</label>
        </div>
      );
    }
    return null;
  };
  const getDistrictColor = (index) => {
    return COLORS[index % COLORS.length];
  };
  ////modal
  const [ShowMemberCouponCount, setShowMemberCouponCount] = useState(false)

   // Function to open the modal
   const openModal = () => {
    setShowMemberCouponCount(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowMemberCouponCount(false);
  };
  const [SelectedDistrictData, setSelectedDistrictData] = useState({})
  const ViewMemberWise=(data)=>{
    console.log(data)
    setSelectedDistrictData(data)
    openModal()
  }
  return (
    <>
        <MemberCouponCountState Show={ShowMemberCouponCount} Close={closeModal} SelectedDistrictData={SelectedDistrictData}/>
    <div className="row" style={{ overflowX: 'hidden' }}>
      <div className="progress-bar-container col-lg-12 col-sm-12">
        <div className="row" style={{ alignItems: 'center' }}>
          <div className="col-12" style={{ textAlign:'center' }}>
            <h2>Book Sales</h2>
          </div>
          <div className="col-12">
            <label className='Progressbar-label'><span className='Progressbar-span'>{SaledCoupons}</span> Books Issued out of {totalcoupons} Books</label>
            <ProgressBar
              animated
              variant="success"
              now={progressPercentage1}
              label={`${progressPercentage1}%`}
              className="ProgressBar mb-3"
            />
          </div>
          <div className="col-12" style={{ textAlign:'center',padding:'20px' }}>
            <h4>District Wise</h4>
          </div>
          {pieData.length>0&&(<div className="col-12"  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <PieChart width={400} height={400} style={{ display: 'block', margin: 'auto' }}>
        <Pie
          data={pieData}
          color="#000000"
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#8884d8"
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
          </div>)}
          <div className="col-12">
          <table className="table table-bordered table-responsive">
  <thead style={{ background: '#064185', color: 'White' }}>
    <tr>
      <th className="text-center">District</th>
      <th className="text-center">Sales</th>
      <th className="text-center">Details</th>
    </tr>
  </thead>
  <tbody>
       {DistrictWisedata.map((data,index)=>(<tr key={index}  >
          <td className="text-center"  style={{ background: getDistrictColor(index) }}>{data.DistrictName}</td>
          <td className="text-center">
            {data.TotalCount}
          </td>
          <td className="text-center">
            <button onClick={() => ViewMemberWise(data)} style={{
        width: 'fit-content',
        fontSize: '11px',
        background: 'rgb(62, 148, 140)',
        color: '#fff',
        height: '30px',
      }}> view more</button>
          </td>
        </tr>))}
  </tbody>
</table>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CouponSalesReportState;
