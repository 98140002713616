import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import GiftPng from '../Images/img/gift.png';
import '../CSS/PrizeProgressBar.css';
import GreenTick from '../Images/img/checked.png';
import GoldCoinPng from '../Images/img/money.png';
import SmartWatchPng from '../Images/img/smart-watch.png';
import DoublePng from '../Images/img/TwoGoldCoin.png';
import TripPng from '../Images/img/Travel.png';

function PriceProgressBar(props) {
  const coupons = props.CouponInHand;
  // const coupons = 6
  const progressPercentage1 = Math.min((coupons / 7) * 100, 100).toFixed(2);
  const progressPercentage2 = Math.min((coupons / 10) * 100, 100).toFixed(2);
  const progressPercentage3 = Math.min((coupons / 20) * 100, 100).toFixed(2);
  const progressPercentage4 = Math.min((coupons / 40) * 100, 100).toFixed(2);

  return (
    <div className="row">
      <div className="progress-bar-container col-lg-12 col-sm-12">
        <div className="row" style={{ alignItems: 'center' }}>
          <div className="col-2" >
          <span className="coupon-count" >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>"By completing 7 books, you have the chance to win a Smartwatche through a lucky draw."</Tooltip>}
                >
                  <img src={SmartWatchPng} alt="Smart Watch" />
                </OverlayTrigger>
            </span>
          </div>
          <div className="col-10">
            <label className='Progressbar-label'>Get <span className='Progressbar-span'>{Math.max(0, 7 - coupons)}</span> books, Get a chance to win a Smartwatch</label>
            <ProgressBar
              animated
              variant="success"
              now={progressPercentage1}
              label={`${progressPercentage1}%`}
              className="ProgressBar mb-3"
            />
          </div>
        </div>
        <div className="row" style={{ alignItems: 'center' }}>
        <div className="col-2">
            <span className="coupon-count">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>"By completing 10 books, you have the chance to win 8g gold coin/a foreign trip through a lucky draw."</Tooltip>}
                >
                  <img src={GoldCoinPng} alt="Gold Coin" />
                </OverlayTrigger>
            </span>
          </div>
          <div className="col-10">
          <label className='Progressbar-label'>Get <span className='Progressbar-span'>{(Math.max(0, 10 - coupons))}</span> books, Get a chance to win 8g Gold coin/ Foreign trip</label>
            <ProgressBar
              animated
              variant="success"
              now={progressPercentage2}
              label={`${progressPercentage2}%`}
              className="ProgressBar mb-3"
            />
          </div>
        </div>
        <div className="row" style={{ alignItems: 'center' }}>
        <div className="col-2">
            <span className="coupon-count">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>"Double Dhamaka! By completing 20 books, you have the chance to win 16g Gold coin / 2 foreign trip through a lucky draw."</Tooltip>}
                >
                  <img src={DoublePng} alt="Double" />
                </OverlayTrigger>
            </span>
          </div>
          <div className="col-10">
          <label className='Progressbar-label'>Get <span className='Progressbar-span'>{Math.max(0, 20 - coupons)}</span> books, Get a chance to win  16g Gold coin / 2 Foriegn trip </label>
            <ProgressBar
              animated
              variant="success"
              now={progressPercentage3}
              label={`${progressPercentage3}%`}
              className="ProgressBar mb-3"
            />
          </div>
        </div>
        <div className="row" style={{ alignItems: 'center' }}>
        <div className="col-2">
            <span className="coupon-count">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>
                    "By completing 40 books, you are guaranteed an exciting foreign trip."</Tooltip>}
                >
                  <img src={TripPng} alt="Trip" />
                </OverlayTrigger>
            </span>
          </div>
          <div className="col-10">
          <label className='Progressbar-label'>Get <span className='Progressbar-span'>{Math.max(0, 40 - coupons)}</span> books more to win an assured Foreign trip</label>
            <ProgressBar
              animated
              variant="success"
              now={progressPercentage4}
              label={`${progressPercentage4}% `}
              className="ProgressBar mb-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriceProgressBar;
