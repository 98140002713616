import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { BaseUrl } from '../Constants/BaseUrl';
const Districtwisereport = () => {
    const [districtwiselist, setDistrictwiselist] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [districtwiseData, setDistrictwiseData] = useState([]);
    const [head,sethead]=useState(false)
  

    //--------------------
    useEffect(() => {
      axios.get(`${BaseUrl}/districts`)
        .then((res) => {
          if (res.data) {
            setDistrictwiselist(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);


    //-------------
    
  ///------------------------------------------------------
    const handleDistrictChange = (selectedOption) => {
      setSelectedDistrict(selectedOption);
      
      axios.get(`${BaseUrl}/couponsinhand?Id=${selectedOption.value}`)
        .then((res) => {
          if (res.data) {
            
            setDistrictwiseData([res.data]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  //----------------------------------------------------
    return (
      <div>
      {
        selectedDistrict && <h4 style={{textAlign:'center',color:'green'}}>{selectedDistrict.label}</h4>

      }
        <div style={{ width: '200px', marginBottom: '20px' }}>
          <Select
            value={selectedDistrict}
            onChange={handleDistrictChange}
            options={districtwiselist.map((district) => ({
              value: district.Id,
              label: district.Name,
              
            }))}
            placeholder="Select District"
          />
        
        </div>
  <div >
  <div style={{ display: 'flex', justifyContent: 'center' }}>
  <table className="table table-hover" style={{ width: '80%' }}>
    <thead style={{background:'#064185',color:'White',textAlign:'center'}}>
      <tr style={{textAlign:'center'}}>
       
       
        <th scope="col">Slno</th>
        <th scope="col">BookNo</th>
        <th scope="col"> Amount</th>
      </tr>
    </thead>
    {districtwiseData.map((d, index) => (
      d.map((district) => (
        <tbody key={district.Number}>
          <tr style={{textAlign:'center'}}>
            
            <td scope="row">{index += 1}</td>
            <td>{district.Number}</td>
            <td>{district.SubscriptionAmount}</td>
          </tr>
           
        </tbody>
        
      ))
    
    ))}
    <tfoot>
    <tr>
      <td colSpan="3" style={{fontWeight:'bold'}}>Total Books: {districtwiseData.flat().length}</td>
    </tr>
  </tfoot>
  </table>
</div>

</div>
      </div>
    );
  }
  
  export default Districtwisereport;
  