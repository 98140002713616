import React from 'react'
import axios from 'axios'
import { BaseUrl } from '../Constants/BaseUrl'
import {useState,useEffect} from 'react'
function DetailedReportForState() {

const [Statedata, setStatedata] = useState([])
  useEffect(()=>{

    axios.get(`${BaseUrl}/getdetaileforstate`)
    .then((response)=>{
      console.log(response.data)
         setStatedata(response.data)
    })


},[])
const data = Statedata.filter(item => item.Name !== "DemoDistrict");

let balanceAmountToStateSum = 0;
let  BalanceBookInHandAmount=0;
let TotalOutstandingfrommembers=0;
let TotalOutstandingfromDistrict=0;
// Calculate the sums for each column
const columnSums = {
  NumberOfClusters: 0,
  TotalMembers: 0,
  CommitmentBooks: 0,
  PurchaseBooks: 0,
  IssueToMember: 0,
  BookInHands: 0,
  PurchaseAmount: 0,
  ByBank: 0,
  CashInHandByDistrictNeg: 0
 
};

data.forEach((item) => {
  for (const key in columnSums) {
    columnSums[key] += item[key] || 0;
  }
});
  return (
    <div>
    <div className='row mb-4'  >
      <div className='col-lg-5 col-6' >

</div>
</div>
<div className='row my-table-container'>
    <div className="col-12 ">
    <table className="table table-bordered table-responsive">
  <thead style={{ background: '#064185', color: 'White',fontSize:'10px'}}>
  <tr>
    <th>Name</th>
    <th>Clusters</th>
  
    <th>Total Members</th>
    <th>Commitment Books</th>
    <th>Purchased Books</th>
    <th>Issued To Member</th>
    <th>Books In Hand</th>
    <th>Purchased Amount</th>
    <th>Payment By Bank</th>
    <th>By Cash to State</th>
    <th>Balance Amount To State</th>
    <th>Balance Book In Hand Amount</th>
    {/* <th>Dealers Total</th> */}
    <th>Total Outstanding from members</th>
    <th>Total Outstanding from District</th>
</tr>

  </thead>
  <tbody>
  {
    console.log(Statedata)
  }
  {Statedata.filter(item=>item.Name !== "DemoDistrict").map((item, index) => (
    balanceAmountToStateSum += isNaN(((item.PurchaseAmount )- (item.ByBank + item.CashInHandByDistrictNeg??0)))?(item.PurchaseAmount-item.ByBank ):(item.PurchaseAmount - (item.ByBank + item.CashInHandByDistrictNeg??0)),
    BalanceBookInHandAmount+=item.BookInHandsAmount,
    TotalOutstandingfrommembers+=item.DelersCHAmount-(isNaN(item.DealerPaiedAmount) ? 0 : item.DealerPaiedAmount),
    TotalOutstandingfromDistrict+=isNaN (item.CashInHandByDistrictPlus- (item.CashInHandByDistrictNeg ?? 0))?0:item.CashInHandByDistrictPlus- (item.CashInHandByDistrictNeg ?? 0),
    <tr key={index}>

      <td data-label="Name" style={{fontSize: '12px',textAlign:'left'}}>{item.Name}</td>
      <td data-label=" Clusters">{item.NumberOfClusters}</td>
      <td data-label="Total Members">{item.TotalMembers}</td>
      <td data-label="Commitment Books">{item.CommitmentBooks}</td>
      <td data-label="Purchased Books">{item.PurchaseBooks}</td>
      <td data-label="Issued To Member">{item.IssueToMember}</td>
      <td data-label="Books In Hand">{item.BookInHands}</td>
      <td data-label="Purchased Amount">{item.PurchaseAmount}</td>
      <td data-label="Payment By Bank">{item.ByBank}</td>
      <td data-label="By Cash to State">{item.CashInHandByDistrictNeg }</td>
      <td data-label="Balance Amount To State">{isNaN(((item.PurchaseAmount )- (item.ByBank + item.CashInHandByDistrictNeg??0)))?(item.PurchaseAmount-item.ByBank ):(item.PurchaseAmount - (item.ByBank + item.CashInHandByDistrictNeg??0))}</td>
      <td data-label="Balance Book In Hand Amount">{item.BookInHandsAmount}</td>
      {/*<td data-label="Dealerstotal">{item.DelersCHAmount}</td>*/}
      <td data-label="Total Outstanding from members">{item.DelersCHAmount-(isNaN(item.DealerPaiedAmount) ? 0 : item.DealerPaiedAmount)}</td>
    { /*<td data-label="Cash In Hand By District">{ isNaN(item.CashInHandByDistrict)?0:item.CashInHandByDistrict-item.ByBank}</td>*/}
    <td data-label="Total Outstanding from District">{ isNaN (item.CashInHandByDistrictPlus- (item.CashInHandByDistrictNeg ?? 0))?0:item.CashInHandByDistrictPlus- (item.CashInHandByDistrictNeg ?? 0)}</td>
    

    </tr>
  ))}

  {/* Calculate the total number of clusters */}
  {/*Statedata.length > 0 && (
    <tr  style={{fontWeight:'bold',backgroundColor:'lightgray'}}>
      <td >Total </td>
      <td>
        {Statedata.reduce((total, item) => total + item.NumberOfClusters, 0)}
      </td>
      <td > {Statedata.reduce((total,item)=>total+item.TotalMembers,0)}</td> 
      <td>{Statedata.reduce((total,item)=>total+item.CommitmentBooks,0)}</td>
      <td > {Statedata.reduce((total,item)=>total+item.PurchaseBooks,0)}</td>
   
      <td > {Statedata.reduce((total,item)=>total+item.IssueToMember,0)}</td>
      <td > {Statedata.reduce((total,item)=>total+item.BookInHands,0)}</td>
      <td > {Statedata.reduce((total,item)=>total+item.PurchaseAmount,0)}</td>
      <td>{(Statedata.reduce((total, item) => total + (item.ByBank ?? 0), 0))}</td>
<td>{(Statedata.reduce((total, item) => total + (item.ByCash ?? 0), 0))}</td>

<td>{(Statedata.reduce((total, item) => total + ((item.PurchaseAmount ?? 0) - ((item.ByBank ?? 0) + (item.ByCash ?? 0))), 0))}</td>

      <td > {Statedata.reduce((total,item)=>total+item.BookInHandsAmount,0)}</td>
      <td > {Statedata.reduce((total,item)=>total+(item.DelersCHAmount-(isNaN(item.DealerPaiedAmount) ? 0 : item.DealerPaiedAmount)),0)}</td>
      <td > {Statedata.reduce((total,item)=>total+(isNaN (item.CashInHandByDistrictPlus- (item.CashInHandByDistrictNeg ?? 0))?0:item.CashInHandByDistrictPlus- (item.CashInHandByDistrictNeg ?? 0)),0)}</td>
    </tr>
  )*/}
</tbody>
<tfoot style={{backgroundColor:'lightgray',fontWeight:'bold'}}>
      <tr >
        <td data-label="Name">Total</td>
        {Object.keys(columnSums).map((key) => (
          <td key={key} data-label={key}>{columnSums[key]}</td>
        ))}
        <td>{balanceAmountToStateSum}</td>
        <td>{BalanceBookInHandAmount}</td>
        <td>{TotalOutstandingfrommembers}</td>
        <td>{TotalOutstandingfromDistrict}</td>
      </tr>
    </tfoot>

</table>

      </div>
      </div>
    </div>
  )
}

export default DetailedReportForState