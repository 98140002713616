import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect,useState } from 'react';
import DistrictCouponPage from './Pages/DistrictCouponPage';
import Login from './Pages/Login'
import StateCommittePage from './Pages/StateCommittePage';
import DealerCouponPage from './Pages/DealerCouponPage'
import DealerCouponPage2 from './Pages/DealerCouponPage2';
import NewLogin from './Pages/NewLogin';
import DealerPageWithSideMenu from './Pages/DealerPageWithSideMenu';
import StatePageWithSideMenu from './Pages/StatePageWithSideMenu';
import DistrictPageWithSideMenu from './Pages/DistrictPageWithSideMenu';
import PrivacyPolicy from './Components/PrivacyPolicy';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
import axios from 'axios';
import { BaseUrl } from './Constants/BaseUrl';
import UpdateNotifictationModal from './Components/UpdateNotifictationModal';
function App() {
  const packageJson = require('../package.json')
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    axios
      .get(BaseUrl + `/getappversion`)
      .then((response) => {
        // console.log(response.data)
        // console.log(packageJson.version)
        if (packageJson.version !== response.data) {
          setShowModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
  
  return (
    <div className="App">
  <Router>
        <Routes>
          {/* <Route path="/" element={<LoginRegistration />} /> */}
          <Route path="/" element={<NewLogin/>} /> 
          <Route path="/district" element={<DistrictPageWithSideMenu />} />
          <Route path="/statecommitte" element={<StatePageWithSideMenu/>} />
          <Route path="/member" element={<DealerPageWithSideMenu/>} />
          <Route path="/sidemenu" element={<DealerCouponPage2/>} />
          <Route path="/sidemenuState" element={<StateCommittePage/>} />
          <Route path="/districtside" element={<DistrictCouponPage/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
        </Routes>
      </Router>
      <UpdateNotifictationModal Show={showModal} Close={closeModal}/>
    </div>
  );
}

export default App;
