import React from 'react'
import Select from 'react-select';
import { useState ,useEffect} from 'react';
import { BaseUrl } from '../Constants/BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function PaymentIntimationState() {
    useEffect(() => {
        axios.get(`${BaseUrl}/districts`)
        .then((response)=>{
             SetDistricts(response.data)
        })
    }, []);

    const [DealersData, setDealersData] = useState([])
    const [SelectedDistrict,SetSelectedDistrict]=useState('default')
    const [SelectedDealer,SetSelectedDealer]=useState('default')
    const [Districts,SetDistricts]=useState([]);
    const [Amount, setAmount] = useState('')
    const [PayDate, setPayDate] = useState(new Date().toISOString().split('T')[0])
    const [Transactionid, setTransactionid] = useState('')
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString)
   
    const SetDistrictWiseMembers=(selectedOption)=>{
       
        try {
          //  console.log(selectedOption.value)
           
           SetSelectedDistrict(selectedOption.value)
            axios.get(BaseUrl+`/getdistrictwisedealers?Id=${selectedOption.value}`)
            .then(response => {
             // console.log(response.data)
            setDealersData(response.data)
            })
            
        } catch (error) {
            console.log(error)
        }
    }

    const HandleSelectedDealer=(selectedOption)=>{
        try {
          
            SetSelectedDealer(selectedOption.value)
        } catch (error) {
           console.log(error) 
        }
    }
const Refresh=()=>{
    setAmount('')
    setTransactionid('')
    SetSelectedDealer('default')
    SetSelectedDistrict('default')
    setPayDate(new Date().toISOString().split('T')[0])
}
    const HandleFormData=()=>{
      if(user.EditingPower===1){
        try {

          let Data={dealerid:SelectedDealer,status:2,pdate:PayDate,amount:Amount,paymode:2,transactionid:Transactionid,did:user.id}
           console.log(Data)
          if(SelectedDealer&&PayDate&&Amount!==0&&Transactionid)
          {
              axios.post(BaseUrl+`/setpayment`,Data)
              .then((res)=>{
                  console.log(res.data);
                  if(res.data=='Paymentadded')
                  {
                    toast.success('Payment Added Successfully!', {
                      position: 'top-center' 
                    });
                      Refresh()     
                  }
                  else
                  {
                      window.alert("failed To add Payment..")
                  }
              })
             
          }
          else
          {
              window.alert("Enter the  fields..")
          }
         
          
      } catch (error) {
          console.log(error)
      }
      }else{
        alert('You do not have the required editing power to perform this action.')
      }
    }
    const options = [
        { value: 'default', label: 'Select a District', isDisabled: true },
        ...Districts.map((data) => ({ value: data.Id, label:data.Name })),
      ];
     
      const dealeroptions = [
        { value: 'default', label: 'Select a Dealer', isDisabled: true },
        ...DealersData.map((data) => ({ value: data.Id, label:data.Name })),
      ];

  return (
    <div className='col-lg-12 col-md-12'>
    <div className='subscription' style={{border: '5px solid #92580a',borderRadius:'20px'}}>
      <div className='row'>
        <h5>Payment</h5>
      </div>
      <div className="row" style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
        {/* <select value={PaymentDealerId} onChange={(e) => setPaymentDealerId(e.target.value)} style={{ width: '250px', height:'40px', textAlign: 'center',borderRadius:'20px'}}>
          <option value="default" disabled>Select a Member</option>
          {DealersData.map((data) => (<option key={data.Id} value={data.Id}>{`${data.DealerNo} - ${data.Shopename}`}</option>))}
        </select> */}
         <Select
         onChange={SetDistrictWiseMembers}
         value={options.find((option) => option.value === SelectedDistrict)}
         options={options}
         styles={{
          control: (provided) => ({
            ...provided,
            width: '250px',
            height: '40px',
            textAlign: 'center',
            borderRadius: '20px',
            margin: '0 auto',
          }),
        }}
      />

      
      </div>
      <div className="row" style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
        {/* <select value={PaymentDealerId} onChange={(e) => setPaymentDealerId(e.target.value)} style={{ width: '250px', height:'40px', textAlign: 'center',borderRadius:'20px'}}>
          <option value="default" disabled>Select a Member</option>
          {DealersData.map((data) => (<option key={data.Id} value={data.Id}>{`${data.DealerNo} - ${data.Shopename}`}</option>))}
        </select> */}
         <Select
        onChange={HandleSelectedDealer}
        value={dealeroptions.find((option)=>option.value === SelectedDealer)}
        options={dealeroptions}
        styles={{
          control: (provided) => ({
            ...provided,
            width: '250px',
            height: '40px',
            textAlign: 'center',
            borderRadius: '20px',
            margin: '0 auto',
          }),
        }}
      />


      </div>
     
      <div className='row' style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
        <input type="number" onChange={(e)=>setAmount(e.target.value)} value={Amount} placeholder="Enter Amount" style={{ width: '250px',height:'40px', textAlign: 'center',borderRadius:'20px' }}   />
      </div>
      <div className="row" style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
        <input type="date" onChange={(e)=>setPayDate(e.target.value)}  value={PayDate} style={{ width: '250px', textAlign: 'center' ,height:'40px', padding:'10px',borderRadius:'20px' }}  />
      </div>
      <div className="row" style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
        <input type="text"  onChange={(e)=>setTransactionid(e.target.value)} value={Transactionid}  placeholder='RefNo/BankName' style={{ width: '250px', textAlign: 'center' ,height:'40px', padding:'10px',borderRadius:'20px' }}  />
      </div>
      <div className='row' style={{ display: 'flex', justifyContent: 'center', padding:'10px'}}>
        <button className="issue-button"  onClick={HandleFormData}>Submit</button>
      </div>
    </div>
  </div>
  )
}

export default PaymentIntimationState