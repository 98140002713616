import React,{useState,useEffect}  from 'react';
import { BaseUrl } from '../Constants/BaseUrl';
import '../CSS/Districtbody.css';
import axios from 'axios';
import RequstedCouponDistrict from '../Components/RequstedCouponDistrict';
import { useLocation } from 'react-router-dom';
import RequstedCouponDistrict2 from '../Components/RequstedCouponDistrict2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../Images/LoginImages/tdaak-logo.png'
import CouponIssueDistrict from '../Components/CouponIssueDistrict';
import PaymentRequestDistrict from '../Components/PaymentRequestDistrict';
function DistrictCouponPage() {
  // const location = useLocation();
  // const { state } = location;
  // const  DistrictId=state.id
  const DistrictId='986581C7-B72C-449D-A6F4-416E620266DE'

  // const couponData = Array.from({ length: 60 }, (_, index) => index + 1);
  const [DistrictCouponData, setDistrictCouponData] = useState([])
  const [DealersData, setDealersData] = useState([])
  const [DealerId, setDealerId] = useState('')
  const [PaymentDealerId, setPaymentDealerId] = useState('')
  const [PaymentAmount, setPaymentAmount] = useState(0)
  const [PaymentDate, setPaymentDate] = useState(new Date().toISOString().split('T')[0])
  const [CouponNumber, setCouponNumber] = useState(0)
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [IsFinished, setIsFinished] = useState(true)
  const [IsMainDashboard, setIsMainDashboard] = useState(false)
  const [IsRequstedCoupons, setIsRequstedCoupons] = useState(true)
  const [IsRequestPayment, setIsRequestPayment] = useState(false)
  const [PaymentRequestData, setPaymentRequestData] = useState([])
const [PaymentRequestFinished, setPaymentRequestFinished] = useState(false)
const toggleIsFinishedPayment = () => {
  setPaymentRequestFinished(!PaymentRequestFinished);
};
  const handleToggleControl = (val) => {
    if (val === 1) {
      setIsMainDashboard(false);
      setIsRequstedCoupons(true);
      setIsRequestPayment(false)
      setActiveControl(1)
    } else if (val === 2) {
      setIsMainDashboard(false);
      setIsRequstedCoupons(false);
      setIsRequestPayment(true)
      setActiveControl(2)
    }else if (val === 3) {
      setIsMainDashboard(true);
      setIsRequstedCoupons(false);
      setIsRequestPayment(false)
      setActiveControl(3)
    }
  };
  const toggleIsFinished = () => {
    setIsFinished(!IsFinished);
  };
  const handleCouponSelection = (couponNumber) => {
    if (selectedCoupons.includes(couponNumber)) {
      setSelectedCoupons(selectedCoupons.filter((coupon) => coupon !== couponNumber));
    } else if (selectedCoupons.length < 20) {
      setSelectedCoupons([...selectedCoupons, couponNumber]);
    }
  }
  useEffect(() => {
    axios.get(BaseUrl+`/couponsinhand?Id=${DistrictId}`)
    .then(response => {
      // console.log(response.data)
     setDistrictCouponData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
  }, [IsFinished])
  useEffect(() => {
    axios.get(BaseUrl+`/getdistrictwisedealers?Id=${DistrictId}`)
    .then(response => {
      // console.log(response.data)
     setDealersData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
   
  }, [])
  useEffect(() => {
    axios.get(BaseUrl+`/getpayrequests?districtid=${DistrictId}&paymode=1&status=0`)
    .then(response => {
      console.log(response.data)
   setPaymentRequestData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
  }, [PaymentRequestFinished])
  
  function IssueCoupon(){
    const formattedCoupons = selectedCoupons.map((couponNumber) => ({
      dealerid: DealerId,
      number: couponNumber
    }));  
    console.log(formattedCoupons)
    if(DealerId.length===0){
      alert('please select a dealer')
    }
    else if(selectedCoupons.length===0){
      alert('please select Book')
    }
    else{
      axios.put(BaseUrl+`/updatedealercmstatus`,formattedCoupons)
      .then(response => {
        setSelectedCoupons([])
        toggleIsFinished()
        toast.success('Book issued successfully!', {
          position: 'top-center' // Set the position to top-center
        });
      })
      .catch(error=> {
          console.log(error);
      });
    }
  }
  function Request(){
    const RequestCouponData={number:CouponNumber,districtid:DistrictId}
    if (CouponNumber!==0) {
      axios.post(BaseUrl+`/setdrequest`,RequestCouponData)
      .then(response => {
        toast.success('Request successful!', {
          position: 'top-center' // Set the position to top-center
        });
      
      })
      .catch(error=> {
          console.log(error);
      });
    }
   else{
    alert('Please Enter required number of Books')
   }
  }
  function Payment(){
    const  paymentData={dealerid:PaymentDealerId,pdate:PaymentDate,amount:PaymentAmount,paymode:1,status:2}
    console.log(paymentData);
    if(PaymentDealerId.length===0||PaymentAmount===0){
      if(PaymentDealerId.length===0){
        alert('Please select a dealer')
      }else if(PaymentAmount===0){
        alert('Please enter a valid amount')
      }
    }
    else{

      axios.post(BaseUrl+`/setpayment`,paymentData)
      .then(response => {
        toast.success('payment successful!', {
          position: 'top-center' // Set the position to top-center
        });
        
      })
      .catch(error=> {
          console.log(error);
      })
    }
 
  }
  //Toggle
  const [activeControl, setActiveControl] = useState(1);
  return (
    <>
<div className="page-header-con order-history" style={{ display: 'flex', alignItems: 'center' }}>
  <img src={Logo} alt="Logo" style={{ float: 'left', marginRight: '10px',width:'75px',height:'35px',marginLeft:'10px'}} />
  <h3 style={{ margin: '0' }}>Eranakulam</h3>          
</div>
      <div className="admin-page-container " >
      <div className="filter-box-wrap">
<div className="container-fluid">
<div className="row">
  <div className="col-4">
    <button
      className={activeControl === 1 ? "active-button" : "inactive-button"}
      onClick={() => handleToggleControl(1)}
    >
      Issue Books
    </button>
  </div>
  <div className="col-4">
    <button
      className={activeControl === 2 ? "active-button" : "inactive-button"}
      onClick={() => handleToggleControl(2)}
    >
      Approve Payment
    </button>
  </div>
  <div className="col-4">
    <button
      className={activeControl === 3 ? "active-button" : "inactive-button"}
      onClick={() => handleToggleControl(3)}
    >
      Request
    </button>
  </div>
</div>

</div>

</div>
          <div className='districtbody' style={{ marginTop:'15px'}}>
            <div className='container'>
           {IsRequstedCoupons&&<RequstedCouponDistrict DistrictId={DistrictId} DistrictCouponData={DistrictCouponData} couponDataRefresh={toggleIsFinished}/>}
           {IsRequestPayment&&<PaymentRequestDistrict PaymentRequestData={PaymentRequestData} toggleIsFinishedPayment={toggleIsFinishedPayment}/>}
           {IsMainDashboard&&
           <div className='row'>
              <div className="col-12" style={{ backgroundColor:'rgb(245, 232, 223)',padding: '10px'}}>
              <div className="district-coupon-selector">
                <div className="district-coupon-list">
                {DistrictCouponData.map((coupon) => (
  <button
    key={coupon.Number}
    className={selectedCoupons.includes(coupon.Number) ? 'selected' : ''}
    onClick={() => handleCouponSelection(coupon.Number)}
    style={{ position: 'relative' }}
  >
    <div style={{ textAlign: 'center' }}>
      {coupon.Number}
    </div>
    <div
      style={{
        fontSize: '12px',
        color:'rgba(0, 0, 0, 0.8)',
        position: 'absolute',
        bottom: '5px',
        left: '5px',
        right: '5px',
        textAlign: 'center',
      }}
    >
      ({coupon.Number * 100 - 99}-{coupon.Number * 100})
    </div>
  </button>
))}
                </div>
              </div>
              <div className="container CouponFooter">
              <div className='col-lg-4 d-flex justify-content-center'>
    <h5>Coupons:{selectedCoupons.length}</h5>
  </div>
  <div className="col-lg-6 d-flex justify-content-center dropdown-container">
    <select  defaultValue="default" onChange={(e) => setDealerId(e.target.value)} style={{ width: '250px',height:'30px', textAlign: 'center' }}>
      <option value="default" disabled>Select a dealer</option>
      {DealersData.map((data)=>(<option key={data.Id} value={data.Id}>{data.Name}</option>))}
    </select>
  </div>
     <div className="col-lg-2 d-flex justify-content-center button-container">
    <button className="issue-button" onClick={IssueCoupon}>Issue</button>
  </div>
    </div>
              </div>
    <div className='col-lg-6 col-md-12'>
  <div className='subscription'>
    <div className='row'>
      <h5>Payment</h5>
    </div>
    <div className="row" style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
      <select defaultValue="default" onChange={(e) => setPaymentDealerId(e.target.value)} style={{ width: '250px', height:'40px', textAlign: 'center',borderRadius:'20px'}}>
        <option value="default" disabled>Select a dealer</option>
        {DealersData.map((data) => (<option key={data.Id} value={data.Id}>{data.Name}</option>))}
      </select>
    </div>
    <div className='row' style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
      <input type="number" placeholder="Enter amount" style={{ width: '250px',height:'40px', textAlign: 'center',borderRadius:'20px' }}    onChange={(e) => setPaymentAmount(parseFloat(e.target.value))}/>
    </div>
    <div className="row" style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
      <input type="date" style={{ width: '250px', textAlign: 'center' ,height:'40px', padding:'10px',borderRadius:'20px' }} value={PaymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
    </div>
    <div className='row' style={{ display: 'flex', justifyContent: 'center', padding:'10px'}}>
      <button className="issue-button" onClick={Payment}>Submit</button>
    </div>
  </div>
</div>
  <div className='col-lg-6 col-md-12'>
  <div className='requestcoupon'>
    <div className='row'>
      <h5 style={{padding:'10px'}}>Request new Books </h5>
    </div>
    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
      <input type="number" placeholder="Enter number of Books" style={{ width: '250px', textAlign: 'center' ,height:'40px',borderRadius:'20px' }} onChange={(e) => setCouponNumber(e.target.value)} />
    </div>
    <div className='row' style={{ display: 'flex', justifyContent: 'center' , padding: '10px' }}>
      <button className="issue-button" onClick={Request}>Request</button>
    </div>
  </div>
</div>
              </div>}
            </div>
          </div>
        </div>
     
    </>
  );
}

export default DistrictCouponPage;
