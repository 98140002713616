import React from 'react'
import axios from 'axios';
import { useState,useEffect } from 'react';
import { BaseUrl } from '../Constants/BaseUrl';
function Getdistrictcountreport() {
    const [sdate, setsdate] = useState('');
  const [edate, setedate] = useState('');
  const [datalist, setdatalist] = useState([]);
  //--------------------
  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;

    const startOfMonth = new Date(year, month - 1, 1);
    const endOfMonth = new Date(year, month, 0);

    const formattedStartDate = startOfMonth.toISOString().substring(0, 10);
    const formattedEndDate = endOfMonth.toISOString().substring(0, 10);

    setsdate(formattedStartDate);
    setedate(formattedEndDate);
 axios
      .get(BaseUrl + '/getdistrictcount', { params: { sdate:formattedStartDate, edate: formattedEndDate } })
      .then((response) => {
        if (response.data) {
            // console.log("response"+response.data)
          setdatalist(response.data);
          // console.log("datalist"+datalist)

        }
      })
      .catch((error) => {
        console.log(error);
      });
    
  }, []);



  const Getdatas=()=>{
    axios
      .get(BaseUrl + '/getdistrictcount', { params: { sdate: sdate, edate: edate } })
      .then((response) => {
        if (response.data) {
            console.log("response"+response.data)
          setdatalist(response.data);
          console.log("datalist"+datalist)

        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  return (
    <div>

   
    
       <div className="container">
      <div className="row justify-content-center">
      <div></div>
        <div className="col-sm-6 col-md-4">
          <div className="input-group mb-3">
            <input
              type="date"
              className="form-control"
              value={sdate}
              onChange={(e) => setsdate(e.target.value)}
            />
            <span className="input-group-addon">
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="input-group mb-3">
            <input
              type="date"
              className="form-control"
              value={edate}
              onChange={(e) => setedate(e.target.value)}
            />
            <span className="input-group-addon">
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="col-md-2">
          <button className="issue-button" onClick={Getdatas}>
            Show
          </button>
        </div>
      </div>

      <table className="table table-hover mt-4">
        <thead style={{background:'#064185',color:'White',textAlign:'center'}}>
          <tr style={{textAlign:'center'}}>
            <th scope="col">Slno</th>
            <th scope="col">Districts</th>           
            <th>No Of Books</th>
          </tr>
        </thead>
        <tbody>
      {
        
        datalist.map((d,index)=>(
           
            <tr key={index} style={{textAlign:'center'}} >
            <td>{index+=1}</td>
              <td scope="row">{d.DistrictName}</td>
              <td>{d.TotalCount}</td>
              
            </tr>
        ))  
           
       
        
      }
      </tbody>
      </table>
    </div>
    </div>
  )
}

export default Getdistrictcountreport