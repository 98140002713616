import React, { useState,useEffect } from 'react';
import '../CSS/DealerPageWithSideMenu.css';
import { Nav, Tab } from 'react-bootstrap';
import Logo from '../Images/LoginImages/tdaak-logo.png';
import ReportPng from '../Images/img/report.png'
import VoucherPng from '../Images/img/voucher.png'
import PieChartPng from '../Images/img/pie-chart.png'
import PaymentPng from '../Images/img/cashless-payment.png'
import SalesPng from '../Images/img/sales.png'
import PaymentRequestDistrict from '../Components/PaymentRequestDistrict';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';
import CouponSelector from '../Components/CouponSelector';
import NotificationModal from '../Components/NotificationModal'
import NotificationModalPayment from '../Components/NotificationModalPayment';
import Datewisedistrictreport from '../Reports/Datewisedistrictreport'
import Detailedreport from '../Reports/Detailedreport'
import Districtwisereport from '../Reports/districtwisereport'
import Getdistrictcountreport from '../Reports/Getdistrictcountreport'
import TargetReport from '../Reports/TargetReport'
import DistrictCountBar from '../PieCharts/DistrictCountBar';
import DistrictCountPie from '../PieCharts/DistrictCountPie';
import TargetPie from '../PieCharts/TargetPie';
import TargetBar from '../PieCharts/TargetBar'
import MasterPng from '../Images/img/table.png'
import MasterTable from '../Components/MasterTable';
import { useNavigate } from 'react-router-dom';
import CouponSalesReportState from '../Components/CouponSalesReportState';
import ProgressBar from 'react-bootstrap/ProgressBar';
import PaymentIntimationState from '../Components/PaymentIntimationState';
import DistrictCommitment from '../Reports/DistrictCommitment';
import DetailedReportForState from '../Reports/DetailedReportForState';
import ClusterWiseReportState from '../Components/ClusterWiseReportState';
import MemberPaymenReportDistrict from '../Components/MemberPaymenReportDistrict';
import MemberPaymentLedgerState from '../Components/MemberPaymentLedgerState';
import PaymentHistoryState from '../Components/PaymentHistoryState';
function StatePageWithSideMenu() {
      //Refresh
      const [startY, setStartY] = useState(0);
      const [refreshThreshold, setRefreshThreshold] = useState(150);
      const [isRefreshing, setIsRefreshing] = useState(false);
      
      useEffect(() => {
        const userString = localStorage.getItem('user');
        const user = JSON.parse(userString); // Parse the string to an object
      
        if (!user || user.usertype !== 'BC763517-EE09-4DFB-8571-64E5E3EF7B64') {
          alert('Access Denied: You are not authorized as a State Committee Member.');
          navigate('/');
        }
      }, []);
    
      useEffect(() => {
        const handleTouchStart = (event) => {
          setStartY(event.touches[0].clientY);
        };
    
        const handleTouchEnd = (event) => {
          const deltaY = event.changedTouches[0].clientY - startY;
          if (deltaY > refreshThreshold) {
            setIsRefreshing(true);
            window.location.reload();
          }
        };
    
        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchend', handleTouchEnd);
    
        return () => {
          document.removeEventListener('touchstart', handleTouchStart);
          document.removeEventListener('touchend', handleTouchEnd);
        };
      }, [refreshThreshold, startY]);
      //////////////////////
  const navigate=useNavigate()
  const [activeTab, setActiveTab] = useState('issue-coupon');

  const handleTabSelect = tab => {
    setActiveTab(tab);
  };
  const [IsReport, setIsReport] = useState(false)
  const [IsChart, setIsChart] = useState(false)
  const handleToggleControl=(val)=>{
    if(val===1){
        setIsReport(true)
        setIsChart(false)
    }
    else if(val===2){
      setIsReport(false)
      setIsChart(true)
    }
}
  //payment request
  const [PaymentRequestFinished, setPaymentRequestFinished] = useState(false)
  const [PaymentRequestData, setPaymentRequestData] = useState([])
  const [PaymentRequestDistrictData, setPaymentRequestDistrictData] = useState([])
  
const toggleIsFinishedPayment = () => {
  setPaymentRequestFinished(!PaymentRequestFinished);
};

const fetchPaymentData = () => {
  axios.get(BaseUrl + '/getpayrequests?districtid=00000000-0000-0000-0000-000000000000&paymode=2&status=0')
    .then(response => {
      // console.log(response.data)
      setPaymentRequestData(response.data);
    })
    .catch(error => {
      console.log(error);
    });

  axios.get(BaseUrl + '/getpayrequests?districtid=00000000-0000-0000-0000-000000000000&paymode=3&status=0')
    .then(response => {
      // console.log(response.data)
      setPaymentRequestDistrictData(response.data);
    })
    .catch(error => {
      console.log(error);
    });
};

  useEffect(() => {
    fetchPaymentData(); // Fetch data immediately when component mounts

    const intervalId = setInterval(() => {
      fetchPaymentData(); 
    }, 3000);

    // Cleanup function to clear the interval when component unmounts or when the dependency changes
    return () => clearInterval(intervalId);
  }, [PaymentRequestFinished])
  //Coupon Issue
  const [DistrictData, setDistrictData] = useState([])
  const [CouponData, setCouponData] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [IsFinished, setIsFinished] = useState(true)
  const toggleIsFinished = () => {
    setIsFinished(!IsFinished);
  };
const [DistrictDetails, setDistrictDetails] = useState({
  Name:'',
  Number:0,
  Id:''
})
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleButtonClick = (Name,Number,DistrictId) => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    if(user.EditingPower===1){
      setDistrictDetails({
        Name:Name,
        Number:Number,
        Id:DistrictId
      })
      toggleIsFinished()
      setShowModal(true);
    }else{
      alert('You do not have the required editing power to perform this action.')
    }
  };

  const fetchCouponData=() => {
    axios.get(BaseUrl+`/drequest`)
    .then(response => {
      // console.log(response.data)
     setDistrictData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
    axios.get(BaseUrl+`/getCoupon`)
    .then(response => {
      // console.log(`CoutponData`+ response.data)
     setCouponData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
  }
  
  useEffect(() => {
    fetchCouponData() // Fetch data immediately when component mounts

    const intervalId = setInterval(() => {
      fetchCouponData() 
    }, 3000)

    // Cleanup function to clear the interval when component unmounts or when the dependency changes
    return () => clearInterval(intervalId);
  }, [IsFinished])
    //Notification
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [showNotificationModalPayment, setshowNotificationModalPayment] = useState(false)
    const handleCloseNotfication = () => {
      setShowNotificationModal(false);
    };
  
    const handleShowNotfication = () => {
      setShowNotificationModal(true);
    };
    const handleCloseNotficationPayment = () => {
      setshowNotificationModalPayment(false);
    };
  
    const handleShowNotficationPayment = () => {
      setshowNotificationModalPayment(true);
    };
    const [IsNotifictionFinished, setIsNotifictionFinished] = useState(true)
const toggleIsNotification = () => {
  setIsNotifictionFinished(!IsNotifictionFinished);
};
const [IsNotifictionFinishedPayment, setIsNotifictionFinishedPayment] = useState(true)
const toggleIsNotificationPayment = () => {
setIsNotifictionFinishedPayment(!IsNotifictionFinishedPayment);
};
const [NotificationData, setNotificationData] = useState([])
const [PaymentNotificatinData, setPaymentNotificatinData] = useState([])
useEffect(() => {
    axios.get(BaseUrl+`/checkescalations`)
    .then(response => {
      const filteredArray = response.data.filter(obj => obj.Stview === 0);
      // console.log(filteredArray);
      setNotificationData(filteredArray)
    })
    .catch(error => {
      console.log(error);
    });

    axios.get(BaseUrl+`/getpaymentescalations`)
    .then(response => {
      // console.log(response.data);
      setPaymentNotificatinData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
}, [IsNotifictionFinished])
//sidebar
const [sidebarVisible, setSidebarVisible] = useState(false);
//style
const styles = {
  brown: '#786450',
  frame: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '400px',
    height: '400px',
    marginTop: '-200px',
    marginLeft: '-200px',
    borderRadius: '2px',
    boxShadow: '1px 2px 10px 0px rgba(0,0,0,0.3)',
    background: '#CA7C4E',
    background: '-webkit-linear-gradient(bottom left, #EEBE6C 0%, #CA7C4E 100%)',
    background: '-moz-linear-gradient(bottom left, #EEBE6C 0%, #CA7C4E 100%)',
    background: '-o-linear-gradient(bottom left, #EEBE6C 0%, #CA7C4E 100%)',
    background: 'linear-gradient(to top right, #EEBE6C 0%, #CA7C4E 100%)',
    filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#EEBE6C", endColorstr="#CA7C4E", GradientType=1)',
    color: '#786450',
    fontFamily: "'Open Sans', Helvetica, sans-serif",
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  center: {
    position: 'fixed',
    top: '56px',
    right: '25px',
    height: '299px',
    width: '200px',
    background: '#fff',
    border: '5px solid #92580a',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '10px 10px 15px 0 rgba(0, 0, 0, 0.3)',
    zIndex: 1025
  },
  profile: {
    float: 'left',
    width: '192px',
    height: '320px',
    textAlign: 'center',
  },
  image: {
    position: 'relative',
    width: '70px',
    height: '70px',
    margin: '28px auto 0 auto',
  },
  circle1: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '76px',
    height: '76px',
    top: '-3px',
    left: '-3px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#786450 #786450 #786450 transparent',
    borderRadius: '50%',
    transition: 'all 1.5s ease-in-out',
  },
  circle2: {
    extend: 'circle1',
    width: '82px',
    height: '82px',
    top: '-6px',
    left: '-6px',
    borderColor: '#786450 transparent #786450 #786450',
  },
  img: {
    display: 'block',
  },
  name: {
    fontSize: '20px',
    fontWeight: '600',
    marginTop: '5px',
  },
  job: {
    fontSize: '15px',
    lineHeight: '15px',
  },
  actions: {
    marginTop: '33px',
  },
  btn: {
    display: 'block',
    width: '120px',
    height: '30px',
    margin: '0 auto 10px auto',
    background: 'none',
    border: '1px solid #786450',
    borderRadius: '15px',
    fontSize: '12px',
    fontWeight: '600',
    boxSizing: 'border-box',
    transition: 'all .3s ease-in-out',
    color: 'red',
  },
  stats: {
    float: 'left',
  },
  box: {
    boxSizing: 'border-box',
    width: '120px',
    height: '99px',
    background: '#F5E8DF',
    textAlign: 'center',
    paddingTop: '28px',
    transition: 'all .4s ease-in-out',
  },
  boxHover: {
    background: '#E1CFC2',
    cursor: 'pointer',
  },
  box2: {
    margin: '1px 0',
  },
  value: {
    fontSize: '18px',
    fontWeight: '600',
  },
  parameter: {
    fontSize: '11px',
  },

  rowStyles: {
    '--bs-gutter-x': 'initial' // Set the property to 'initial' to remove it
  }
};
function handleToggleSidebar() {
  setSidebarVisible(!sidebarVisible);
}
//Logout
function Logout() {
  const confirmMessage = "Are you sure you want to logout?"

  if (window.confirm(confirmMessage)) {
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('userRole');
  navigate('/')
  }
}

//Progress Bar

useEffect(() => {
  axios.get(BaseUrl+'/getinitsettings')
  .then((res)=>{
    if(res.data)
    {
      console.log(res.data[0].TotalCoupons)
   settotalcoupons(res.data[0].TotalCoupons)
    }
  })
}, []);

const [totalcoupons,settotalcoupons]=useState(0)
console.log("TotalCoupon"+totalcoupons)
console.log(CouponData.length)
const SaledCoupons = (totalcoupons-(CouponData.length))
const progressPercentage1 = Math.min((SaledCoupons / totalcoupons) * 100, 100).toFixed(2);
  return (
    <>
    <CouponSelector showModal={showModal} onClose={handleModalClose} CouponData={CouponData} DistrictDetails={DistrictDetails} toggleIsFinished={toggleIsFinished}/>
    <NotificationModal showModal={showNotificationModal} handleClose={handleCloseNotfication} NotificationData={NotificationData} toggleIsNotification={toggleIsNotification}/>
    <NotificationModalPayment showModal={showNotificationModalPayment} handleClose={handleCloseNotficationPayment} NotificationData={PaymentNotificatinData} toggleIsNotification={toggleIsNotificationPayment}/>
    <div className="bodybg cv-bodypadding">
      <div className="header-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-5">
              <img src={Logo} className="cv-reg-taglahlogo" alt="SaveDate" />
            </div>
            <div className="col-lg-6 col-md-6 col-7">
            <button className={`notification-bell ${PaymentNotificatinData.length > 0 ? 'active' : ''}`} onClick={handleShowNotficationPayment}>
            <i className="fa-sharp fa-light fa-triangle-exclamation"></i>
              </button>
              <button className={`notification-bell ${NotificationData.length > 0 ? 'active' : ''}`} onClick={handleShowNotfication}>
                <i className="far fa-bell"></i>
              </button>
              <button className="notification-bell" onClick={handleToggleSidebar}><i id="menubtn" className={`fas ${sidebarVisible ? 'fa-times' : 'fa-user-circle'}`}></i></button>
            </div>
          </div>
        </div>
      </div>
      {sidebarVisible && (
      <div style={styles.center}>
        <div style={styles.profile}>
          <div style={styles.image}>
            <div style={styles.circle2}>
            <img
              src={Logo}
              width="70"
              height="60"
              alt="TDAAK"
              style={styles.img}
            />
            </div>
          </div>
          <div style={styles.name}>State Committee</div>
          <div style={styles.actions}>
          <button style={styles.btn} onClick={Logout}>Logout</button>
           <button style={{ ...styles.btn, color: 'blue' }} onClick={()=>navigate('/member')} >Member page</button>
          </div>
        </div>
      </div>
      )}
      <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
        <div className="savedate-sidebar">
          <Nav className="flex-column" fill variant="pills" id="v-pills-tab" aria-orientation="vertical">
            <Nav.Link eventKey="issue-coupon" style={{ textAlign: 'left'}} className='StateLink'>
              <span style={{backgroundImage: `url(${VoucherPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
              <h6>Issue Books</h6>
            </Nav.Link>
            <Nav.Link eventKey="Approve-dealer-payment" style={{ textAlign: 'left'}} className='StateLink'>
              <span style={{backgroundImage: `url(${PaymentPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
              <h6>Approve Member Payment</h6>
            </Nav.Link>
            <Nav.Link eventKey="Approve-district-payment" style={{ textAlign: 'left'}} className='StateLink'>
              <span style={{backgroundImage: `url(${PaymentPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
              <h6>Approve District Payment</h6>
            </Nav.Link>
            <Nav.Link eventKey="Book-Sales" style={{ textAlign: 'left'}} className='StateLink'>
              <span style={{backgroundImage: `url(${SalesPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
              <h6>Book Sales</h6>
            </Nav.Link>
            <Nav.Link eventKey="master" style={{ textAlign: 'left'}} className='StateLink'>
              <span style={{backgroundImage: `url(${MasterPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
              <h6>Master</h6>
            </Nav.Link>
            <Nav.Link eventKey="payment-key" style={{ textAlign: 'left'}} className='StateLink'>
              <span style={{backgroundImage: `url(${VoucherPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
              <h6>Payment Intimation</h6>
            </Nav.Link>
            <Nav.Link eventKey="report-6" style={{ textAlign: 'left' }}   className='StateLink'>
            <span style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
                  <h6>Districtwise Commitment</h6>
                </Nav.Link>
                <Nav.Link eventKey="report-7" style={{ textAlign: 'left' }}   className='StateLink'>
            <span style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
                  <h6>Districtwise Detailed</h6>
                </Nav.Link>
                <Nav.Link eventKey="report-8" style={{ textAlign: 'left' }}   className='StateLink'>
            <span style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
                  <h6>ClusterWise Report</h6>
                </Nav.Link>
                <Nav.Link eventKey="report-9" style={{ textAlign: 'left' }}   className='StateLink'>
            <span style={{backgroundImage: `url(${VoucherPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
                  <h6>Member Payment Ledger</h6>
                </Nav.Link>
                <Nav.Link eventKey="report-10" style={{ textAlign: 'left' }}   className='StateLink'>
            <span style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
                  <h6>Transaction History</h6>
                </Nav.Link>
            <div style={{ textAlign: 'left',cursor:'Pointer' }} onClick={()=>handleToggleControl(1)}  className='nav-link StateLink'>
              <span style={{backgroundImage: `url(${ReportPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
              <h6>Reports</h6>
            </div>

            {IsReport && (
              <Nav className="flex-column sub-nav" fill variant="pills" id="sub-nav-reports" aria-orientation="vertical">
                <Nav.Link eventKey="report-1" style={{ textAlign: 'left' }}  className='StateLink'>
                  <span></span>
                  <h6>Detailed Report</h6>
                </Nav.Link>
                <Nav.Link eventKey="report-2" style={{ textAlign: 'left' }}  className='StateLink'>
                  <span></span>
                  <h6>District count Report</h6>
                </Nav.Link>
                <Nav.Link eventKey="report-3" style={{ textAlign: 'left' }}   className='StateLink'>
                  <span></span>
                  <h6>Datewise Report</h6>
                </Nav.Link>
                {/* <Nav.Link eventKey="report-4" style={{ textAlign: 'left' }}  className='StateLink'>
                  <span></span>
                  <h6>District Report</h6>
                </Nav.Link> */}
                <Nav.Link eventKey="report-5" style={{ textAlign: 'left' }}   className='StateLink'>
                  <span></span>
                  <h6>Target Report</h6>
                </Nav.Link>
                
                
              </Nav>
            )}

            <div style={{ textAlign: 'left',cursor:'Pointer' }} onClick={()=>handleToggleControl(2)}  className='nav-link StateLink'>
              <span style={{backgroundImage: `url(${PieChartPng})`,backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
              <h6>Charts</h6>
            </div>

            {IsChart && (
              <Nav className="flex-column sub-nav" fill variant="pills" id="sub-nav-charts" aria-orientation="vertical">
                <Nav.Link eventKey="chart-1" style={{ textAlign: 'left' }} className='StateLink'>
                  <span></span>
                  <h6>District count Piechart</h6>
                </Nav.Link>
                <Nav.Link eventKey="chart-2" style={{ textAlign: 'left' }}  className='StateLink'>
                  <span></span>
                  <h6>District Count BarChart</h6>
                </Nav.Link>
                <Nav.Link eventKey="chart-3" style={{ textAlign: 'left' }} className='StateLink'>
                  <span></span>
                  <h6>Target Piechart</h6>
                </Nav.Link>
                <Nav.Link eventKey="chart-4" style={{ textAlign: 'left' }}  className='StateLink'>
                  <span></span>
                  <h6>Target Barchart</h6>
                </Nav.Link>
              </Nav>
            )}

           

           
          </Nav>
        </div>
        <div className="container-fluid">
          <Tab.Content id="v-pills-tabContent" style={{marginTop:'70px'}}>
          <Tab.Pane eventKey="issue-coupon">
          <div className="col-12">
            <label className='Progressbar-label'><span className='Progressbar-span'>{SaledCoupons}</span> Books Issued out of {totalcoupons} Books</label>
            <ProgressBar
              animated
              variant="success"
              now={progressPercentage1}
              label={`${progressPercentage1}%`}
              className="ProgressBar mb-3"
            />
          </div>   
  <div style={{ padding: '10px' }}>
    {DistrictData.length === 0 ? (
      <p className="text-center">No data available</p>
    ) : (
      <table className="table table-bordered table-responsive">
        <thead style={{ background: '#064185', color: 'White', textAlign: 'center' }}>
          <tr>
            <th>District</th>
            <th>No of books Requested</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: 'center' }}>
          {DistrictData.map((data) => (
            <tr key={data.DistrictId} style={{ textAlign: 'center', backgroundColor: '#f8f9fa' }}>
              <td>
                <b>{data.DistrictName}</b>
              </td>
              <td>{data.Number}</td>
              <td>
                <button className='issue-button' onClick={() => handleButtonClick(data.DistrictName, data.Number, data.DistrictId)}>Issue</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
</Tab.Pane>

            <Tab.Pane eventKey="Approve-dealer-payment">
              <PaymentRequestDistrict PaymentRequestData={PaymentRequestData} From={0} toggleIsFinishedPayment={toggleIsFinishedPayment} Mode='Bank'/>
            </Tab.Pane>
            <Tab.Pane eventKey="Approve-district-payment">
            <PaymentRequestDistrict PaymentRequestData={PaymentRequestDistrictData} From={1} toggleIsFinishedPayment={toggleIsFinishedPayment} Mode='Bank'/>
            </Tab.Pane>
            <Tab.Pane eventKey="Book-Sales">
            <CouponSalesReportState CouponData={CouponData}/>
            </Tab.Pane>
            <Tab.Pane eventKey="master">
           <MasterTable/>
            </Tab.Pane>
            <Tab.Pane eventKey="payment-key">
           <PaymentIntimationState/>
            </Tab.Pane>
            <Tab.Pane eventKey="report-1">
              <Detailedreport/>
            </Tab.Pane>
            <Tab.Pane eventKey="report-2">
              <Getdistrictcountreport/>
            </Tab.Pane>
            <Tab.Pane eventKey="report-3">
              <Datewisedistrictreport/>
            </Tab.Pane>
            <Tab.Pane eventKey="report-8">
              <ClusterWiseReportState/>
            </Tab.Pane>
            <Tab.Pane eventKey="report-9">
             <MemberPaymentLedgerState/>
            </Tab.Pane>
            <Tab.Pane eventKey="report-10">
             <PaymentHistoryState/>
            </Tab.Pane>
            {/* <Tab.Pane eventKey="report-4">
              <Districtwisereport/>
            </Tab.Pane> */}
            <Tab.Pane eventKey="report-5">
             <TargetReport/>
            </Tab.Pane>
            <Tab.Pane eventKey="report-6">
             <DistrictCommitment/>
            </Tab.Pane>
            <Tab.Pane eventKey="report-7">
             <DetailedReportForState/>
            </Tab.Pane>
            <Tab.Pane eventKey="chart-1">
            <DistrictCountPie/>
            </Tab.Pane>
            <Tab.Pane eventKey="chart-2">
            <DistrictCountBar/>
            </Tab.Pane>
            <Tab.Pane eventKey="chart-3">
           <TargetPie/>
            </Tab.Pane>
            <Tab.Pane eventKey="chart-4">
            <TargetBar/>
            </Tab.Pane>
            
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
    </>
  );
}

export default StatePageWithSideMenu;
