import { findAllByDisplayValue } from '@testing-library/react';
import React, { useState ,useEffect} from 'react'
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function EditModalMasters(props) {
    useEffect(() => {
        setMemberId(props.MemberDetails.Id)
        SetSelectedtype(props.MemberDetails.UserTypeId)
        SetSelectedDistrict(props.MemberDetails.DistrictId)
        setShopName(props.MemberDetails.ShopName)
        setMemberAddress(props.MemberDetails.Address)
        SetMemberName(props.MemberDetails.Name)
        setMemberCode(props.MemberDetails.DealerCode)
         setMemberNumber(props.MemberDetails.MobileNo)
        setCommitment(props.MemberDetails.TargetCoupons)
    }, [props.MemberDetails])
    const [MemberId, setMemberId] = useState('')
    const [Selectedtype,SetSelectedtype]=useState('default')
    const [SelectedDistrict,SetSelectedDistrict]=useState('default')
    const [MemberName,SetMemberName]=useState('')
    const [MemberCode, setMemberCode] = useState('')
    const [MemberNumber, setMemberNumber] = useState('')
    const [ShopName, setShopName] = useState('')
    const [MemberAddress, setMemberAddress] = useState('')
    const [Commitment, setCommitment] = useState(0)
    
const SetSelectedUsertypes=(options)=>{
  console.log(options.value)
    SetSelectedtype(options.value)
}

const SetSelectedDistricts = (e) => {
    const userConfirmed = window.confirm("Are you sure you want to change the district?");
    if (userConfirmed) {
      SetSelectedDistrict(e.value)
    }
  }
const EditDetails = () => {
    const MemberData={DealerId:MemberId,UserTypeId:Selectedtype,DealerNo:MemberCode,Name:MemberName,MobileNo:MemberNumber,ShopName,Address:MemberAddress,DistrictId:SelectedDistrict,TargetCoupons:Commitment}
  try {
    axios.put(BaseUrl + `/Updatedealer`,MemberData)
      .then(response => {
        console.log(response.data)
        if(response.data[0].error===0){
            toast.success('Member Details Edited Successfully!', {
                position: 'top-center' 
              });
              props.Hide()
              props.RefreshData()
        }else{
            toast.error('Edit failed!', {
                position: 'top-center' 
              });
        }
      })
      .catch(error => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

const options = [
  { value: 'default', label: 'Select a Type', isDisabled: true },
  ...props.Usertype.map((data) => ({ value: data.Id, label:data.Name })),
];

const districtoptions = [
  { value: 'default', label: 'Select a District', isDisabled: true },
  ...props.DistrictData.map((data) => ({ value: data.Id, label:data.Name })),
];
  return (
    <div>

    <Modal show={props.Show} onHide={props.Hide}>
    <Modal.Header closeButton >
      <Modal.Title><h5 style={{color:'orange'}}>Add Member</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-lg-12">
          <div className="input-item">
            <label><span style={{color:'orange'}}></span></label>
            <input className="form-control" onChange={(e)=>SetMemberName(e.target.value)} value={MemberName}  style={{color:'orange'}} cols="30" required rows="10" placeholder="name *"  />
            <br></br>
            <input className="form-control" onChange={(e)=>setMemberCode(e.target.value)} value={MemberCode} style={{color:'orange'}} cols="30" required rows="10" placeholder="MemberCode *"  />
           <br></br>
           <Select
            
            onChange={SetSelectedDistricts}
           value={ districtoptions.find((option) => option.value === SelectedDistrict)}
            options={districtoptions}
            styles={{
             control: (provided) => ({
               ...provided,
               
              
             }),
           }}
         />
            <br></br>
            <Select
         onChange={SetSelectedUsertypes}
        value={options.find((option) => option.value === Selectedtype)}
         options={options}
         styles={{
          control: (provided) => ({
            ...provided,
           
          }),
        }}
      />
      <br></br>
            <input type='Number' onChange={(e)=>setMemberNumber(e.target.value)} value={MemberNumber} style={{color:'orange'}} cols="30" required rows="10" placeholder="Phone number *"  className="form-control no-spinners"/>
            <br></br>
            <input className="form-control" onChange={(e)=>setShopName(e.target.value)} value={ShopName}  style={{color:'orange'}} cols="30" required rows="10" placeholder="Shop name *"  />
            <br></br>
            <input className="form-control" onChange={(e)=>setMemberAddress(e.target.value)} value={MemberAddress} style={{color:'orange'}} cols="30" required rows="10" placeholder="Address *"  />
            <br></br>
            <input className="form-control" type='number' value={Commitment} onChange={(e)=>setCommitment(e.target.value)} style={{color:'orange'}} cols="30" required rows="10" placeholder="Commitment."  />
            <br></br>
           </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button   type="button" className="addnew-btn" onClick={EditDetails} >Submit</button>
    </Modal.Footer>
  </Modal>
    </div>
  )
}

export default EditModalMasters