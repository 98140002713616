import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl';
import axios from 'axios';
import Select from 'react-select'
function ClusterWiseReportState() {
    const [DistrictId, setDistrictId] = useState('default')
    const [ClusterId, setClusterId] = useState('default')
    const [Districts, setDistricts] = useState([])
    const [ReportData, setReportData] = useState([])
    const Cluster = [
         { Id: 'A', Name: 'Cluster A' },{ Id: 'B', Name: 'Cluster B' },{ Id: 'C', Name: 'Cluster C' },{ Id: 'D', Name: 'Cluster D' },{ Id: 'E', Name: 'Cluster E' },
        { Id: 'F', Name: 'Cluster F' }, { Id: 'G', Name: 'Cluster G' }, { Id: 'H', Name: 'Cluster H' }, { Id: 'I', Name: 'Cluster I' },
        { Id: 'J', Name: 'Cluster J' },{ Id: 'K', Name: 'Cluster K' },{ Id: 'L', Name: 'Cluster L' },{ Id: 'M', Name: 'Cluster M' },
        { Id: 'N', Name: 'Cluster N' },{ Id: 'O', Name: 'Cluster O' },{ Id: 'P', Name: 'Cluster P' },  { Id: 'Q', Name: 'Cluster Q' }, { Id: 'R', Name: 'Cluster R' },{ Id: 'S', Name: 'Cluster S' },{ Id: 'T', Name: 'Cluster T' },{ Id: 'U', Name: 'Cluster U' }, { Id: 'V', Name: 'Cluster V' },{ Id: 'W', Name: 'Cluster W' }, { Id: 'X', Name: 'Cluster X' }, { Id: 'Y', Name: 'Cluster Y' }, { Id: 'Z', Name: 'Cluster Z' }
      ]
    useEffect(() => {
          axios.get(`${BaseUrl}/districts`)
          .then((response)=>{
            setDistricts(response.data)
          }).catch((error) => {
            console.log(error);
          });
      }, []);
      useEffect(() => {
        if(ClusterId!=='default'&&DistrictId!=='default'){
            axios.get(`${BaseUrl}/GetClusterwiseDetailed?disid=${DistrictId}&cluster=${ClusterId}`)
            .then((response)=>{
             console.log(response.data)
             setReportData(response.data)
            }).catch((error) => {
              console.log(error);
            });
        }
      }, [DistrictId,ClusterId])
      
    const option1 = [
        { value: 'default', label: 'Select a District', isDisabled: true },
        ...Districts.map((data) => ({ value: data.Id, label: `${data.Name}` })),
      ]
      const option2 = [
        { value: 'default', label: 'Select a Cluster', isDisabled: true },
        ...Cluster.map((data) => ({ value: data.Id, label: `${data.Name}` })),
      ];
  return (
    <>
   <div className="row justify-content-center">
        <div className="col-sm-6 col-md-4">
          <div className="input-group mb-3">
          <Select
      value={option1.find((option) => option.value === DistrictId)}
      onChange={(selectedOption) => setDistrictId(selectedOption.value)}
      options={option1}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '250px',
          height: '40px',
          textAlign: 'center',
          margin: '0 auto',
        }),
      }}
    />
            <span className="input-group-addon">
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="input-group mb-3">
          <Select
      value={option2.find((option) => option.value ===ClusterId)}
      onChange={(selectedOption) => setClusterId(selectedOption.value)}
      options={option2}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '250px',
          height: '40px',
          textAlign: 'center',
          margin: '0 auto',
        }),
      }}
    />
            <span className="input-group-addon">
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
      </div>
  
       <div className='row my-table-container'>
       
     <div className="col-12 ">
       <table className="table table-bordered table-responsive">
         <thead style={{ background: '#064185', color: 'White' }}>
           <tr>
           <th className="text-center">Slno</th>
           <th className="text-center">MemberId</th>
             <th className="text-center">ShopName</th>
             <th className="text-center">Place</th>
             <th className="text-center">Six Month Offer</th>
             <th className="text-center">Total Purchase</th>
             <th className="text-center">Amount Paid</th>
             <th className="text-center">Balance</th>
           </tr>
         </thead>
         <tbody>
             {ReportData.map((data,index)=>(
             <tr className="text-center" >
               <td data-label="Slno">{index+1}</td>
               <td data-label="MemberId">{data.DealerNo}</td>
               <td data-label="ShopName">{data.ShopName}</td>
               <td data-label="Place">{data.Address}</td>
               <td data-label=">Six Month Offer">{data.SixMonthOffer}</td>
               <td data-label="Total Purchase">{data.purchase}</td>
               <td data-label="Amount Paid">
                {data.AmountPaid}
               </td>
               <td data-label="Balance">
                {data.Balance}
              </td>
             </tr>))}
         </tbody>
       </table>
       </div>
       </div>
     </>
  )
}

export default ClusterWiseReportState