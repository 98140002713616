import React, { useState,useEffect } from 'react';
import '../CSS/CouponSelector.css'; // Import the CSS file for the component
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function CouponIssueDistrict(props) {
    const [selectedCoupons, setSelectedCoupons] = useState([]);
    const [inputNumber, setInputNumber] = useState('');
    const handleCouponSelection = (input) => {
      const sanitizedInput = String(input).replace(/\s/g, '');
    
      if (/^\d+$/.test(sanitizedInput)) {
        const couponNumber = parseInt(sanitizedInput);
        if (
          selectedCoupons.includes(couponNumber) ||
          !props.DistrictCouponData.some((coupon) => coupon.Number === couponNumber)
        ) {
          setSelectedCoupons(selectedCoupons.filter((coupon) => coupon !== couponNumber));
        } else if (selectedCoupons.length < props.DealerDetails.Number) {
          setSelectedCoupons([...selectedCoupons, couponNumber]);
        }
      } else if (/^\d+(,\d+)*$/.test(sanitizedInput)) {
        const couponNumbers = sanitizedInput.split(',').map((number) => parseInt(number));
        const validCoupons = couponNumbers.filter(
          (number) =>
            !selectedCoupons.includes(number) &&
            props.DistrictCouponData.some((coupon) => coupon.Number === number)
        );
        const remainingSlots = props.DealerDetails.Number - selectedCoupons.length;
        const couponsToAdd = validCoupons.slice(0, remainingSlots);
        setSelectedCoupons([...selectedCoupons, ...couponsToAdd]);
      } else if (/^\d+-\d+$/.test(sanitizedInput)) {
        const [start, end] = sanitizedInput.split('-').map((number) => parseInt(number));
        if (start <= end) {
          const range = Array.from({ length: end - start + 1 }, (_, index) => start + index);
          const validCoupons = range.filter(
            (number) =>
              !selectedCoupons.includes(number) &&
              props.DistrictCouponData.some((coupon) => coupon.Number === number)
          );
          const remainingSlots = props.DealerDetails.Number - selectedCoupons.length;
          const couponsToAdd = validCoupons.slice(0, remainingSlots);
          setSelectedCoupons([...selectedCoupons, ...couponsToAdd]);
        }
      }
    };
      //calculate coupon in hand
  const [CouponInHand, setCouponInHand] = useState(0)
  const [PaymentData, setPaymentData] = useState([])
  function calculateRowCountSum(array) {
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
      if (typeof array[i].RowCount === 'number') {
        sum += array[i].RowCount;
      }
    }
    setCouponInHand(sum)
  }
  useEffect(() => {
    if(props.DealerDetails.Id){
      axios.get(`${BaseUrl}/getdealercoupondash?did=${props.DealerDetails.Id}`)
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          calculateRowCountSum(res.data)
        }
      })
      .catch((error) => console.log(error));
    
    axios.get(`${BaseUrl}/getpaymentaccounts?dealerid=${props.DealerDetails.Id}`)
    .then((res) => {
   console.log(res.data)
   setPaymentData(res.data)
    })
    .catch((error) => console.log(error));
  }
  }, [props.DealerDetails.Id])
   //payment total
  // Calculate the total amount
  const totalAmount = PaymentData.reduce((total, data) => {
    return data.Status === 0 ? total - data.amount : total + data.amount;
  }, 0);
  const result = totalAmount > 0 ? 0 : Math.abs(totalAmount);

// Determine the color for "Amount to be paid"
const amountColor = totalAmount < 0 ? 'red' : 'green';

const [BookIssueButton, setBookIssueButton] = useState(false)
    function IssueCoupon(){
      const userString = localStorage.getItem('user');
    const user = JSON.parse(userString)
        setBookIssueButton(true)
      const formattedCoupons = selectedCoupons.map((couponNumber) => ({
        dealerid:props.DealerDetails.Id,
        number: couponNumber,
        did:user.id
      }));  
      // console.log(formattedCoupons)
      // if(props.DealerDetails.Number===formattedCoupons.length){
        axios.put(BaseUrl+`/updatedealercmstatus`,formattedCoupons)
        .then(response => {
          if(response.data){
            const updatedrequest={did:props.DealerDetails.Id,date:props.DealerDetails.RequestDate,noofapprove:selectedCoupons.length}
            axios.put(BaseUrl+`/updatedealerreq`,updatedrequest)
            .then(response => {
              console.log(response.data)
              setSelectedCoupons([])
              toast.success('Books issued successfully!', {
                position: 'top-center' // Set the position to top-center
              });
              props.couponDataRefresh()
              props.toggleIsFinished()
              props.onClose()

            })
            .catch(error=> {
                console.log(error);
            });
          }
        })
        .catch(error=> {
            console.log(error);
        }).finally(() => {
          // Enable the button again after the request is completed
          setBookIssueButton(false)
        });
      // }
      // else{
      //  alert(`Dealer Requsted ${props.DealerDetails.Number} books but you only selcted ${formattedCoupons.length} books`)
      // }
    }
    //style
    const styles={
      rowStyles: {
        '--bs-gutter-x': 'initial' // Set the property to 'initial' to remove it
      }
    }
  return (
    <Modal show={props.showModal} onHide={() => {
        setSelectedCoupons([])
        props.onClose();
      }}>
      <Modal.Body>
      <button type="button" style={{width:'29px',height:'29px',position:'absolute',top:'-10px',right:'-10px',borderRadius:'50%',background:'#e0dbd8',color:'black'}} onClick={ ()=>{props.onClose()}}>
      <i class="fas fa-times"></i>
			</button>
      <div className="row" style={styles.rowStyles}>
      <div className="col-4 mb-4">
    <div className="Modalcard">
      <span className="big">{props.DealerDetails.Name}</span>
      <span className="small">{props.DealerDetails.Code}</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="Modalcard">
      <span className="big" >{CouponInHand}</span>
      <span className="small" >Books in hand</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="Modalcard">
      <span className="big" style={{color:amountColor}}>₹{result}</span>
      <span className="small">Payment pending</span>
    </div>
  </div>
     </div>
<div className='row mb-4'>
  <div className='col-9'>
        <input
              type="text"
              value={inputNumber}
              onChange={(e) => setInputNumber(e.target.value)}
              placeholder="Enter numbers or range"
              style={{width:'100%', fontSize: '18px'}}
            />
      </div>
      <div className='col-3'>
             <button type="submit" className='issue-button' onClick={() => handleCouponSelection(inputNumber)} >Select</button>
       </div>
       </div>

        <div className="modalbody">
          <div className="coupon-selector">
            <div className="coupon-list">
            {props.DistrictCouponData.map((coupon) => (
  <button
    key={coupon.Number}
    className={selectedCoupons.includes(coupon.Number) ? 'selected' : ''}
    onClick={() => handleCouponSelection(coupon.Number)}
    style={{ position: 'relative' }}
  >
    <div style={{ textAlign: 'center' }}>
      {coupon.Number}
    </div>
    <div
      style={{
        fontSize: '8px',
        color:'rgba(0, 0, 0, 0.8)',
        position: 'absolute',
        bottom: '5px',
        left: '5px',
        right: '5px',
        textAlign: 'center',
      }}
    >
      ({coupon.Number * 100 - 99}-{coupon.Number * 100})
    </div>
  </button>
))}
            </div>
          </div>
        </div>

        <div className="row mt-3">
  <div className="col-9">
    <label style={{fontSize: '19px', fontWeight: 600}}>Selected books: <span style={{color: 'green',fontWeight:'700',fontSize: '22px'}}>{selectedCoupons.length}</span></label>
  </div>
  <div className="col-3">
    <button className="issue-button" onClick={IssueCoupon} disabled={BookIssueButton} >{BookIssueButton? 'wait..' : 'Issue'}</button>
  </div>
</div>

      </Modal.Body>
    </Modal>
  )
}

export default CouponIssueDistrict