import { findAllByDisplayValue } from '@testing-library/react';
import React, { useState ,useEffect} from 'react'
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
function MastresModal(props) {

    const [Selectedtype,SetSelectedtype]=useState('default')
    const [SelectedDistrict,SetSelectedDistrict]=useState('default')
    const [MemberName,SetMemberName]=useState('')
    const [MemberCode, setMemberCode] = useState('')
    const [MemberNumber, setMemberNumber] = useState('')
    const [ShopeName, setShopeName] = useState('')
    const [MemberAddress, setMemberAddress] = useState('')
    const [Commitment, setCommitment] = useState(0)
    
const SetSelectedUsertypes=(options)=>{
  console.log(options.value)
    SetSelectedtype(options.value)
}

const SetSelectedDistricts=(e)=>{
  console.log(e.value)
  SetSelectedDistrict(e.value)
}

const SetData = () => {
  try {
    if (
      Selectedtype &&SelectedDistrict &&MemberName &&   MemberCode &&  MemberNumber && ShopeName && MemberAddress && Commitment) {
      props.SetDealersData(
        Selectedtype,
        SelectedDistrict,
        MemberName,
        MemberCode,
        MemberNumber,
        ShopeName,
        MemberAddress,
        Commitment
      );

      // Clear the input fields after successful submission
      setShopeName('');
      SetMemberName('')
      setMemberNumber('');
      setMemberAddress('');
      setCommitment('');
      setMemberCode('');
      setCommitment(0)
      SetSelectedUsertypes('default')
      SetSelectedDistrict('default')
    } else {
       window.alert("Please fill in all fields before submitting.");
    }
  } catch (error) {
    console.log(error);
  }
};

const options = [
  { value: 'default', label: 'Select a Type', isDisabled: true },
  ...props.Usertype.map((data) => ({ value: data.Id, label:data.Name })),
];

const districtoptions = [
  { value: 'default', label: 'Select a District', isDisabled: true },
  ...props.DistrictData.map((data) => ({ value: data.Id, label:data.Name })),
];

  return (


    <div>

    <Modal show={props.status} onHide={props.onClose}>
    <Modal.Header closeButton >
      <Modal.Title><h5 style={{color:'orange'}}>Add Member</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-lg-12">
          <div className="input-item">
            <label><span style={{color:'orange'}}></span></label>
            <input className="form-control" onChange={(e)=>SetMemberName(e.target.value)} value={MemberName}  style={{color:'orange'}} cols="30" required rows="10" placeholder="name *"  />
            <br></br>
            <input className="form-control" onChange={(e)=>setMemberCode(e.target.value)} value={MemberCode} style={{color:'orange'}} cols="30" required rows="10" placeholder="MemberCode *"  />
           <br></br>
           <Select
            
            onChange={SetSelectedDistricts}
           value={ districtoptions.find((option) => option.value === SelectedDistrict)}
            options={districtoptions}
            styles={{
             control: (provided) => ({
               ...provided,
               
              
             }),
           }}
         />
            <br></br>
            <Select
         onChange={SetSelectedUsertypes}
        value={options.find((option) => option.value === Selectedtype)}
         options={options}
         styles={{
          control: (provided) => ({
            ...provided,
           
          }),
        }}
      />
      <br></br>
            <input className="form-control no-spinners" type='Number' onChange={(e)=>setMemberNumber(e.target.value)} value={MemberNumber} style={{color:'orange'}} cols="30" required rows="10" placeholder="Phone number *"  />
            <br></br>
            <input className="form-control" onChange={(e)=>setShopeName(e.target.value)} value={ShopeName}  style={{color:'orange'}} cols="30" required rows="10" placeholder="Shop name *"  />
            <br></br>
            <input className="form-control" onChange={(e)=>setMemberAddress(e.target.value)} value={MemberAddress} style={{color:'orange'}} cols="30" required rows="10" placeholder="Address *"  />
            <br></br>
        { /*   <select
             style={{ height: '30px',width:'100%' }}
             onChange={SetSelectedUsertypes}
            >
            <option value="" >Select Type</option>
                {
                    props.Usertype.map((item)=>(
                        <option key={item.id} value={item.Id}>
                            {item.Name}
                        </option>

                    ))
                }
            </select>
            */
        }
            
         { /*  <select
            style={{ height: '30px',width:'100%' }}
            onChange={SetSelectedDistricts}
            >
              <option value="" >Select District</option>
             { props.DistrictData.map((item)=>(
              <option key={item.Id} value={item.Id}>
                   {item.Name}
              </option>
             ))
             }
            </select>
            */

         }
            <br></br>
        
            <input className="form-control" type='number' onChange={(e)=>setCommitment(e.target.value)} style={{color:'orange'}} cols="30" required rows="10" placeholder="Commitment."  />
            <br></br>
           </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button   type="button" className="addnew-btn" onClick={SetData} >Submit</button>
    </Modal.Footer>
  </Modal>
    </div>
  )
}

export default MastresModal