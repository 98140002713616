import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl';
function DistrictPaymentHistory(props) {
    const [PaymentHistoryData, setPaymentHistoryData] = useState([])
    const [IsRejectedPayment, setIsRejectedPayment] = useState(false)
    const [IsPaymentLedger, setIsPaymentLedger] = useState(true)
    const [PaymentLedgerData, setPaymentLedgerData] = useState([])
    const [CouponInHand, setCouponInHand] = useState(0)
    function getStatusText(status) {
        switch (status) {
          case 0:
            return ` Payment Intimation Pending..`;
          case 2:
            return `Payment Intimation approved`;
          case 1:
            return `Payment Intimation rejected`;
          default:
            return 'Unknown';
        }
      }
      function getStatusColor(status) {
        switch (status) {
          case 0:
            return 'blue';
          case 2:
            return 'green';
          case 1:
            return 'red';
          default:
            return 'black'; // Default color if status is unknown or not found in switch cases
        }
      }
      function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        return `${formattedDay}/${formattedMonth}/${year}`;
      }
     useEffect(() => {
      axios.get(`${BaseUrl}/Getdistricttostate?Id=${props.DistrictId}&Type=${2}`)
      .then((res) => {
        const filteredData = res.data.filter((item) => item.status === 1)
        console.log(res.data)
          setPaymentHistoryData(filteredData)
      })
      .catch((error) => console.log(error));
      axios.get(`${BaseUrl}/getdistrictaccounts?districtId=${props.DistrictId}`)
      .then((res) => {
          setPaymentLedgerData(res.data)
          // console.log(res.data)
      })
      .catch((error) => console.log(error));
     }, [props.DistrictId])

     if (!Array.isArray(PaymentHistoryData)) {
      return <p>Loading...</p>; // You can render a loading message or return null here
    }
    //Table change
    const ChangeTable =(x)=>{
      if(x===1){
        setIsPaymentLedger(true)
        setIsRejectedPayment(false)
      }
      else if(x===2){
        setIsPaymentLedger(false)
        setIsRejectedPayment(true)
      }
    }
    const styles={
      rowStyles: {
        '--bs-gutter-x': 'initial' // Set the property to 'initial' to remove it
      }
    }
    //payment total
  // Calculate the total amount
  const totalAmount = PaymentLedgerData.reduce((total, data) => {
    if (data.Particulars === 'District') {
      return total + data.amount;
    } else {
      return total - data.amount;
    }
  }, 0);
  
  const result = totalAmount > 0 ? 0 : Math.abs(totalAmount);
  
  // Determine the color for "Amount to be paid"
  const amountColor = totalAmount < 0 ? 'red' : 'green';
  


  return (
    <>
      <div className="row" style={styles.rowStyles}>
      <div className="col-4 mb-4">
    <div className="card">
      <span className="big">{props.TotalCashCollected}</span>
      <span className="small">Total Cash Collected</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big">{props.BookInHand}</span>
      <span className="small">Books in Hand</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="card">
      <span className="big" style={{ color: amountColor }}>₹{result}</span>
      <span className="small">Payment pending</span>
    </div>
  </div>
      </div>
      {IsPaymentLedger&&
      <div className='col-lg-12'>
        <div className='text-center'>
          <h3 style={{ padding: '10px' }}>District Payment Ledger</h3>
        </div>
        <label style={{color: 'red', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=>ChangeTable(2)}>Rejected payments</label>
        <table className="table table-bordered table-responsive">
            <thead style={{background:'#064185',color:'White'}}>
              <tr>
                <th className="text-center">Date</th>
                <th className="text-center">Particulars</th>
                <th className="text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
            {PaymentLedgerData.map((data, index) => (
  <tr key={index}>
    <td className="text-center">{formatDate(data.Date)}</td>
    <td className="text-center">{ data.Particulars === 'District' ? 'Bank' :data.Particulars}</td>
    <td className="text-center" style={{ color: data.Particulars === 'District' ? 'green' : 'red' }}>
    {data.Particulars === 'District' ? '+' : '-'}₹{Math.abs(data.amount)}
    </td>
  </tr>
))}
<tr>
  <td className="text-center"></td>
 {totalAmount<=0?<td className="text-center"><strong>Amount to be paid</strong></td>:<td className="text-center"><strong>extra amount paid</strong></td>}
  <td className="text-center" style={{ color: amountColor }}>
    <strong>₹{Math.abs(totalAmount)}</strong>
  </td>
</tr>
</tbody>

          </table>

      </div>}
    {IsRejectedPayment&&
    <div className="col-lg-12 my-table-container">
    <div className="text-center">
        <h3 style={{padding:'10px'}} >Rejected Payments</h3>
      </div>
      <label style={{color: 'red', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=>ChangeTable(1)}>District payment Ledger</label>
      <table className="table table-bordered table-responsive">
     <thead style={{ background: '#064185', color: 'White' }}>
       <tr>
       <th className="text-center">Date</th>
          <th className="text-center">Amount</th>
          <th className="text-center">Reason</th>
    </tr>
  </thead>
  <tbody>
  {PaymentHistoryData.length === 0 ? (
    <tr>
      <td colSpan="3" className="text-center">
        No data available
      </td>
    </tr>
  ) : (
    PaymentHistoryData.map((data, index) => (
      <tr key={index}>
        <td data-label="Date">{formatDate(data.PaymentDate)}</td>
        <td data-label="Amount" style={{ fontWeight: '700' }}>
          {data.Amount}
        </td>
        <td
          data-label="Remarks"
          style={{
            color: 'red',
            fontWeight: '700',
          }}
        >
             {data.Remarks === null || data.Remarks === undefined || data.Remarks.length === 0
            ? '-'
            : data.Remarks}
        </td>
      </tr>
    ))
  )}
</tbody>
     </table>
    </div>}
    </>
  )
}

export default DistrictPaymentHistory