import React, { useState, useEffect } from 'react';
import '../CSS/NewLogin.css';
import axios from 'axios';
import Logo from '../Images/LoginImages/tdaak-logo.png';
import { BaseUrl } from '../Constants/BaseUrl';
import { useNavigate } from 'react-router-dom';
import {Modal } from 'react-bootstrap';
import PrivacyPolicy from '../Components/PrivacyPolicy';
function NewLogin() {
  const navigate = useNavigate();
  const [Email, setEmail] = useState('');
  const [OTP, setOTP] = useState('');
  const [Phone, setPhone] = useState('');
  const [IsLogin, setIsLogin] = useState(true);
  const [IsOtp, setIsOtp] = useState(false);
  const [IsPhone, setIsPhone] = useState(true);
  const [IsEmail, setIsEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
//Modal
const [show, setShow] = useState(false);
const [userType, setuserType] = useState('')
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    if (isAuthenticated()) {
      navigateToDashboard();
    }
  }, []);

  const handleToggleControl = (val) => {
    if (val === 1) {
      setIsLogin(true);
      setIsOtp(false);
    } else if (val === 2) {
      setIsLogin(false);
      setIsOtp(true);
    }
  };

  const handleToggleControl2 = (val) => {
    if (val === 1) {
      setIsPhone(true);
      setIsEmail(false);
    } else if (val === 2) {
      setIsPhone(false);
      setIsEmail(true);
    }
  };

  const isAuthenticated = () => {
    return localStorage.getItem('isLoggedIn') === 'true';
  };

  const navigateToDashboard = () => {
    const userRole = localStorage.getItem('userRole');
    if (userRole === 'dealer') {
      navigate('/member');
    } else if (userRole === 'district') {
      navigate('/district');
    } else if (userRole === 'statecommittee') {
      navigate('/statecommitte');
    }
  };

  const storeLoginState = (userRole) => {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('userRole', userRole);
  };

  const GenerateOTP = () => {
    setIsLoading(true);
    const LoginData = {phone:Phone}
    if (Phone.length===0) {
      alert('Please enter Phone number')
      setIsLoading(false);
    }else{
    axios.post(BaseUrl + '/getotpwhatsapp', LoginData)
      .then((response) => {
        console.log(response.data)
        if (response.data === 'Success') {
          handleToggleControl(2)
        } else {
          alert('User not found')
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  };
function DealerDashboard(){
  storeLoginState('dealer');
  navigate('/member');
}
function DistrictDashboard(){
  storeLoginState('district');
  navigate('/district');
}
function StateDashboard(){
  storeLoginState('statecommittee');
  navigate('/statecommitte');
}
  const UserLogin = () => {
    setIsLoading(true);
    const LoginData = { email:Phone, otp: OTP };
    axios
      .post(BaseUrl + '/login', LoginData)
      .then((response) => {
        console.log(response.data)
        if (response.data) {
          const userData = response.data[0]
          const user = {
            id: userData.id,
            Name: userData.Dealer,
            district: userData.Name[1],
            target: userData.target,
            usertype:userData.UserTypeId,
            EditingPower:userData.IsEditingRight
          }
          console.log(user)
          localStorage.setItem('user', JSON.stringify(user));
          if (userData.UserTypeId === 'CA8E85F3-16C4-40CC-8A5D-1CC9A406A2D9') {
            DealerDashboard()
          } else if (userData.UserTypeId === 'F4688F9E-702F-4147-A44A-99866FE9124A') {
            localStorage.setItem('DistrictId', JSON.stringify(userData.DistrictId ));
            setuserType('district')
            handleShow()
          } else if (userData.UserTypeId === 'BC763517-EE09-4DFB-8571-64E5E3EF7B64') {
            setuserType('state')
            handleShow()
          } else {
            alert('User not found')
          }
        } else {
          alert('Login failed');
        }
      })
      .catch((error) => {
        console.log(error);
        alert('Invalid OTP');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
//privacy policy
const [showPrivacy, setShowPrivacy] = useState(false);
const handlePrivacyClose = () => setShowPrivacy(false);
const handlePrivacyShow = () => setShowPrivacy(true);
  return (
    <>
    <div className="login-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="login-wrapper">
              <span>
                <img src={Logo} alt="Tdaak Logo" />
              </span>
              <h3>Welcome</h3>
              {IsLogin && IsEmail && <h6>Please enter your email</h6>}
              {IsLogin && IsPhone && <h6>Please enter your Phone number</h6>}
              {IsOtp && <h6>Please Enter OTP</h6>}
              {IsEmail && IsLogin && (
                <div className="form-group">
                  <i className="fas fa-user"></i>
                  <input
                    type="email"
                    placeholder="Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              )}
              {IsPhone && IsLogin && (
                <div className="form-group">
                  <i className="fas fa-lock"></i>
                  <input
                    type="number"
                    placeholder="Phone"
                    value={Phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="no-spinners"
                  />
                </div>
              )}
              {IsOtp && (
                <div className="form-group">
                  <i className="fas fa-lock"></i>
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    value={OTP}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                </div>
              )}
              {IsLogin && (
                <button onClick={GenerateOTP}>
                  {isLoading ? 'Loading...' : 'Continue'}
                </button>
              )}
              {IsOtp && (
                <button onClick={UserLogin}>
                  {isLoading ? 'Loading...' : 'Continue'}
                </button>
              )}
              {IsLogin && IsPhone && (
                <h6
                  style={{
                    color: 'blue',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    padding: '15px'
                  }}
                  // onClick={() => handleToggleControl2(2)}
                  onClick={handlePrivacyShow}
                >
                  Privacy Policy
                </h6>
              )}
              {IsLogin && IsEmail && (
                <h6
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    padding: '10px'
                  }}
                  onClick={() => handleToggleControl2(1)}
                >
                  Login using Phone
                </h6>
              )}
              {isLoading && <div className="loading-animation"></div>}
            </div>
          </div>
        </div>
      </div>
    </div>
      <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
      <button type="button" style={{width:'29px',height:'29px',position:'absolute',top:'-10px',right:'-10px',borderRadius:'50%',background:'#e0dbd8',color:'black'}} onClick={handleClose}>
      <i class="fas fa-times"></i>
			</button>
      <div className='row mb-3'>
       <label style={{textAlign:'center',fontSize:'28px',fontWeight:'600'}}>Choose your page</label>
       </div>
       <div className='row'>
        <div className='col-lg-6 col-sm-12 mb-3'>
          <button style={{width:'100%',background:'white',border:'2px solid red',fontSize:'20px',fontWeight:'400',color:'blue'}} onClick={DealerDashboard}>Member Page</button>
          </div>  
        {userType=='state'&&<div className='col-lg-6 col-sm-12 mb-3'>
          <button style={{width:'100%',background:'white',border:'2px solid red',fontSize:'20px',fontWeight:'400',color:'blue'}} onClick={StateDashboard}>Statecommittee page</button>
        </div>}
        {userType=='district'&&<div className='col-lg-6 col-sm-12 mb-3'>
          <button style={{width:'100%',background:'white',border:'2px solid red',fontSize:'20px',fontWeight:'400',color:'blue'}} onClick={DistrictDashboard}>Districtcommittee page</button>
        </div>}
       </div>
      </Modal.Body>
    </Modal>
    <PrivacyPolicy showPrivacy={showPrivacy} handlePrivacyClose={handlePrivacyClose}/>
    </>
  );
}

export default NewLogin;
