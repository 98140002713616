import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BaseUrl } from '../Constants/BaseUrl';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../CSS/DealerPage.css'
import Logo from '../Images/LoginImages/tdaak-logo.png'
function DealerCouponPage2() {
  const location = useLocation();
  const { state } = location;
  const dealerid=state.id
  // const dealerid='67999EC3-FD99-47EF-B352-152BD74623EF'
  const [dealrdata, setDealrdata] = useState([]);
  const [PaymentData, setPaymentData] = useState([])
  const [LastReqDate,setLastReqDate]=useState('')
  const [CouponNumber, setCouponNumber] = useState(0)
  const [IsFinished, setIsFinished] = useState(true)
  const toggleIsFinished = () => {
    setIsFinished(!IsFinished);
  };
  useEffect(() => {
    //coupon details
    axios.get(`${BaseUrl}/getdealercoupondash?did=${dealerid}`)
      .then((res) => {
        if (res.data) {
          // console.log(res.data)
          setDealrdata(res.data); 
        }
      })
      .catch((error) => console.log(error)); //hard coded
      //payment history
      axios.get(`${BaseUrl}/getdealerpayments?did=${dealerid}`)
      .then((res) => {
    //  console.log(res.data)
     setPaymentData(res.data)
      })
      .catch((error) => console.log(error)); //hard coded
  }, [])
useEffect(() => {
  axios.get(`${BaseUrl}/lastreqdate?did=${dealerid}`)
  .then((res) => {
 setLastReqDate(res.data[0].RequestDate)
  })
  .catch((error) => console.log(error));
}, [IsFinished])

  function Request(){
    const RequestCouponData={number:CouponNumber,dealerid}
    if (CouponNumber!==0) {
      axios.post(BaseUrl+`/setdealerrequest`,RequestCouponData)
      .then(response => {
        toggleIsFinished()
        toast.success('Request successful!', {
          position: 'top-center' // Set the position to top-center
        });
        setCouponNumber(0);
      })
      .catch(error=> {   
          console.log(error);
          toast.error('An error occurred.', {
            position: 'top-center' // Set the position to top-center
          });
      });
    }
   else{
    alert('Please Enter required number of coupons')
   }
  }
  //date conversion
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  function getTotalColor() {
    const total = dealrdata.reduce((total, payment) => total + payment.TotalCount, 0);
    const target = state.target; // Change this to the actual target value
  
    if (total > target) {
      return 'green';
    } else if (total < target) {
      return 'red';
    } else {
      return 'black';
    }
  }
  //Select
  const [activeControl, setActiveControl] = useState(1);
  const [IsRequestCoupon, setIsRequestCoupon] = useState(true)
  const [IsCouponHistory, setIsCouponHistory] = useState(false)
  const [IsPaymentHistory, setIsPaymentHistory] = useState(false)

  const handleToggleControl=(val)=>{
    if(val===1){
      setIsRequestCoupon(true)
      setIsPaymentHistory(false)
      setIsCouponHistory(false)
      setActiveControl(1)
    }
    else if(val===2){
      setIsRequestCoupon(false)
      setIsPaymentHistory(true)
      setIsCouponHistory(false)
      setActiveControl(2)
    }
    else if(val===3){
      setIsRequestCoupon(false)
      setIsPaymentHistory(false)
      setIsCouponHistory(true)
      setActiveControl(3)
    }
}
//Payment Request
const [PaymentDate, setPaymentDate] = useState(new Date().toISOString().split('T')[0])
const [PaymentMode, setPaymentMode] = useState('')
const [PaymentAmount, setPaymentAmount] = useState(0)
  return (
<div>
<div className="page-header-con order-history"> 
<img src={Logo} alt="Logo" style={{ float: 'left', marginRight: '10px',width:'75px',height:'35px',marginLeft:'10px'}} />
  <h3>{state.Dealer}</h3>
</div>
<div className="filter-box-wrap">
<div className="container-fluid">
<div className="row">
  <div className="col-4">
    <button
      className={activeControl === 1 ? "active-button" : "inactive-button"}
      onClick={() => handleToggleControl(1)}
    >
      Request Books
    </button>
  </div>
  <div className="col-4">
    <button
      className={activeControl === 2 ? "active-button" : "inactive-button"}
      onClick={() => handleToggleControl(2)}
    >
      Payment History
    </button>
  </div>
  <div className="col-4">
    <button
      className={activeControl === 3 ? "active-button" : "inactive-button"}
      onClick={() => handleToggleControl(3)}
    >
      Book History
    </button>
  </div>
</div>

</div>

</div>


  <div className="admin-page-container order-history-container">
    <div className="container">
      <div className="row">
       {IsPaymentHistory&&<div className="col-lg-12">
        <div className="text-center">
            <h3 style={{padding:'10px'}} >Payment history</h3>
          </div>
          <table className="table table-bordered table-responsive">
            <thead>
              <tr>
                <th className="text-center">Date</th>
                <th className="text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
  {PaymentData.map((payment) => (
    <tr key={payment.id}>
      <td className="text-center">{formatDate(payment.paymentdate)}</td>
      <td className="text-center">{payment.amount}</td>
    </tr>
  ))}
  <tr style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
    <td className="text-center">Total</td>
    <td className="text-center">
      {PaymentData.reduce((total, payment) => total + payment.amount, 0).toFixed(2)}
    </td>
  </tr>
  <tr style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
    <td className="text-center">Amount to be paid</td>
    <td className="text-center">
    {dealrdata.reduce((total, payment) => total + payment.TotalCount, 0)*2000}
    </td>
  </tr>
  <tr style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
    <td className="text-center">Balance Amount</td>
    <td className="text-center">
    {(
      dealrdata.reduce((total, payment) => total + payment.TotalCount, 0) * 2000 -
      PaymentData.reduce((total, payment) => total + payment.amount, 0)
    ).toFixed(2)}
    </td>
  </tr>
</tbody>

          </table>
        </div>}
        {IsCouponHistory&&<div className="col-lg-12">
        <div className="text-center">
            <h3 style={{padding:'10px'}}>Coupon History</h3>
          </div>
          <table className="table table-bordered table-responsive">
            <thead>
              <tr>
                <th className="text-center">Date</th>
                <th className="text-center">No</th>
              </tr>
            </thead>
            <tbody>
              {dealrdata.map((data)=>(<tr>
                <td className="text-center">{formatDate(data.IssueDate)}</td>
                <td className="text-center">{data.TotalCount}</td>
              </tr>))}
              <tr style={{ textAlign: 'end', fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
                <td className="text-center">Total</td>
                <td className="text-center" style={{ color: getTotalColor() }}>
                {dealrdata.reduce((total, payment) => total + payment.TotalCount, 0)}
                  </td>
              </tr>
              <tr style={{ textAlign: 'end', fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
                <td className="text-center">Target</td>
                <td className="text-center">
               {state.target}
                  </td>
              </tr>
            </tbody>
          </table>
        </div>}
       {IsRequestCoupon&&<div className="col-12">
          <div className="requestcoupon">
            <div className="row" style={{padding:'10px'}}>
              <h4>
                Request new books<h6 style={{fontWeight:'2px', color: 'red' }}>(Last requested on {formatDate(LastReqDate)})</h6>
              </h4>
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                type="number"
                placeholder="Enter number of Books"
                style={{ width: '250px',minHeight:'40px' ,textAlign: 'center' }}
                onChange={(e) => setCouponNumber(parseFloat(e.target.value))}
              />
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <button className="issue-button" style={{width:'115px',height:'40px'}} onClick={Request}>Request</button>
            </div>
          </div>

          <div className="requestcoupon">
            <div className="row" style={{padding:'10px'}}>
              <h4>
                Request Payment
              </h4>
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
           <input type="date" style={{ width: '250px',minHeight:'40px' ,textAlign: 'center' }} value={PaymentDate} onChange={(e) => setPaymentDate(e.target.value)} />
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center', padding:'10px' }}>
            <select name="paymentMethod" style={{ width: '250px',minHeight:'40px' ,textAlign: 'center' }}>
            <option value="cash" disabled>Select payment mode</option>
           <option value="cash">Cash Payment</option>
           <option value="bank">Bank Payment</option>
          </select>
          </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                type="number"
                placeholder="Enter Amount"
                style={{ width: '250px',minHeight:'40px' ,textAlign: 'center' }}
              />
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <button className="issue-button" style={{width:'115px',height:'40px'}}>Request</button>
            </div>
          </div>
        </div>}
      </div>
    </div>
  </div>
</div>

  );
}

export default DealerCouponPage2;