import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl';
import axios from 'axios';
import Select from 'react-select'
import PaymentHistoryDealer from './PaymentHistoryDealer';
import CouponHistoryDealer from './CouponHistoryDealer';
function MemberPaymentLedgerState() {
    
    
    const [Districts, setDistricts] = useState([])
    const [PaymentData, setPaymentData] = useState([])
    const[Members,SetMembers]=useState([])
    const [SelectedDistrict,SetSelectedDistrict]=useState('default')
    const[SelectedMember,SetSelectedMember]=useState('default')
    //const Cluster = [
    //     { Id: 'A', Name: 'Cluster A' },{ Id: 'B', Name: 'Cluster B' },{ Id: 'C', Name: 'Cluster C' },{ Id: 'D', Name: 'Cluster D' },{ Id: 'E', Name: 'Cluster E' },
     //   { Id: 'F', Name: 'Cluster F' }, { Id: 'G', Name: 'Cluster G' }, { Id: 'H', Name: 'Cluster H' }, { Id: 'I', Name: 'Cluster I' },
     //   { Id: 'J', Name: 'Cluster J' },{ Id: 'K', Name: 'Cluster K' },{ Id: 'L', Name: 'Cluster L' },{ Id: 'M', Name: 'Cluster M' },
     //   { Id: 'N', Name: 'Cluster N' },{ Id: 'O', Name: 'Cluster O' },{ Id: 'P', Name: 'Cluster P' },  { Id: 'Q', Name: 'Cluster Q' }, { Id: 'R', Name: 'Cluster R' },{ Id: 'S', Name: 'Cluster S' },{ Id: 'T', Name: 'Cluster T' },{ Id: 'U', Name: 'Cluster U' }, { Id: 'V', Name: 'Cluster V' },{ Id: 'W', Name: 'Cluster W' }, { Id: 'X', Name: 'Cluster X' }, { Id: 'Y', Name: 'Cluster Y' }, { Id: 'Z', Name: 'Cluster Z' }
     // ]
     useEffect(() => {
          axios.get(`${BaseUrl}/districts`)
          .then((response)=>{
            setDistricts(response.data)
          }).catch((error) => {
            console.log(error);
          });


       }, []);

    



    //  useEffect(() => {
     //   if(ClusterId!=='default'&&DistrictId!=='default'){
      //      axios.get(`${BaseUrl}/GetClusterwiseDetailed?disid=${DistrictId}&cluster=${ClusterId}`)
      //      .then((response)=>{
       //      console.log(response.data)
        //     setReportData(response.data)
       //     }).catch((error) => {
       //       console.log(error);
       //     });
      //  }
      //}, [DistrictId,ClusterId])
      
    const option1 = [
        { value: 'default', label: 'Select a District', isDisabled: true },
        ...Districts.map((data) => ({ value: data.Id, label: `${data.Name}` })),
      ]

     
     
     
      const HanldeSetSelectedDistrict=(selectedOption)=>{
        try {
            console.log(selectedOption.value)
           SetSelectedDistrict(selectedOption.value)
           axios.get(BaseUrl+`/getdistrictwisedealers?Id=${selectedOption.value}`)
           .then(response => {
            // console.log(response.data)
           SetMembers(response.data)
           })
        } catch (error) {
           console.log(error) 
        }

        
    }
    const HanldeSetSelectedMembers=(selectedOption)=>{
        try {
            console.log(selectedOption.value)
          // SetSelectedDistrict(selectedOption.value)
          
           SetSelectedMember(selectedOption.value)
           axios.get(`${BaseUrl}/getpaymentaccounts?dealerid=${selectedOption.value}`)
           .then((res) => {
          console.log(res.data)
          setPaymentData(res.data)
           })
          
        } catch (error) {
           console.log(error) 
        }}

          //date conversion
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

          // Calculate the total amount
const totalAmount = PaymentData.reduce((total, data) => {
  return data.Status === 0 ? total - data.amount : total + data.amount;
}, 0);


// Determine the color for "Amount to be paid"
const amountColor = totalAmount < 0 ? 'red' : 'green';

    const option2 = [
        { value: 'default', label: 'Select  Member', isDisabled: true },
        ...Members.map((data) => ({ value: data.Id, label: `${data.Name}` })),
      ]
  return (
    <>
   <div className="row justify-content-center">
        <div className="col-sm-6 col-md-4">
          <div className="input-group mb-3">
          <Select
      value={option1.find((option) => option.value === SelectedDistrict)}
      onChange={HanldeSetSelectedDistrict}
      options={option1}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '250px',
          height: '40px',
          textAlign: 'center',
          margin: '0 auto',
        }),
      }}
    />
            <span className="input-group-addon">
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="input-group mb-3">
          <Select
      value={option2.find((option) => option.value === SelectedMember)}
      onChange={HanldeSetSelectedMembers}
      options={option2}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '250px',
          height: '40px',
          textAlign: 'center',
          margin: '0 auto',
        }),
      }}
    />
    
            <span className="input-group-addon">
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
      </div>
  
       <div className='row my-table-container'>
       
     <div className="col-12 ">
   
     <table className="table table-bordered table-responsive">
            <thead style={{background:'#064185',color:'White'}}>
              <tr>
                <th className="text-center">Date</th>
                <th className="text-center">Particulars</th>
                <th className="text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
            {PaymentData.map((data, index) => (
  <tr key={index}>
    <td className="text-center">{formatDate(data.Date)}</td>
    <td className="text-center">{data.Particulars}</td>
    <td className="text-center" style={{ color: data.Status === 0 ? 'red' : 'green' }}>
    {data.Status === 0 ? '-' : '+'}₹{data.amount}
    </td>
  </tr>
))}
<tr>
  <td className="text-center"></td>
 {totalAmount<=0?<td className="text-center"><strong>Amount to be paid</strong></td>:<td className="text-center"><strong>extra amount paid</strong></td>}
  <td className="text-center" style={{ color: amountColor }}>
    <strong>₹{Math.abs(totalAmount)}</strong>
  </td>
</tr>
</tbody>
</table>
   
   
  
       </div>
       </div>
     </>
  )
}

export default MemberPaymentLedgerState