import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BaseUrl } from '../Constants/BaseUrl';
import '../CSS/UpdateNotificationModal.css';

function UpdateNotifictationModal(props) {
  const [PlaystoreLink, setPlaystoreLink] = useState('https://play.google.com/store/apps/details?id=com.init.tdaak');

  useEffect(() => {
    axios
      .get(BaseUrl + `/getinitsettings`)
      .then((response) => {
        // console.log(response.data[0].PlaystoreUrl);
        setPlaystoreLink(response.data[0].PlaystoreUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.Show]);

  const handleUpdateClick = () => {
    window.location.href = PlaystoreLink;
  };

  return (
    <Modal show={props.Show} size="sm" onHide={props.close} centered>
      <Modal.Body className="alert-boxwrap updatecancel-btn">
        <i class="fas fa-exclamation-circle"></i>
        <h5>Warning</h5>
        <p>'Update Required', 'Please update the app to the latest version!'</p>
        <div className="row">
          <div className="col-12">
            <button
              className="updatebtn"
              style={{ width: '100%', background: '#ec5e77', color: '#fff', marginBottom: '15px' }}
              onClick={handleUpdateClick}
            >
              Update Now
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateNotifictationModal;
