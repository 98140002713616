import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';

function TargetReport() {
  const [data, setData] = useState([]);
  const [selectedValue, setselectedValue] = useState('7');

  useEffect(() => {
      axios.get(BaseUrl+ `/fourkcoupon?num=${selectedValue}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [selectedValue]);

  const handleSelectChange = (e) => {
    setselectedValue(e.target.value);
  };

  return (
    <div>
    <div className='row mb-4'  >
      <div className='col-lg-5 col-6' >
      <label style={{ fontWeight: '700' }}>Select Target:</label>
<select id="mySelect"  value={selectedValue} onChange={handleSelectChange} style={{  width: '100%',maxWidth:'150px',height:'30px' }}>
  <option disabled>Select Target</option>
  <option value="7">7 Books</option>
  <option value="10">10 Books</option>
  <option value="20">20 Books</option>
  <option value="40">40 Books</option>
</select>
</div>
</div>
<div className='row my-table-container'>
    <div className="col-12 ">
      <table className="table table-hover">
        <thead style={{background:'#064185',color:'White',textAlign:'center'}}>
          <tr>
            <th>Slno</th>
          <th>Member Code</th>
            <th>Name</th>
            <th>District</th>
            <th>Books</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td data-label="index">{index+1}</td>
              <td  data-label="Member Code">{item.DealerNo}</td>
              <td data-label="Name">{item.name}</td>
              <td data-label="District">{item.district}</td>
              <td data-label="Books">{item.books}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
    </div>
  )
}

export default TargetReport;