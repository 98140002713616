import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { BaseUrl } from '../Constants/BaseUrl';
function Detailedreport() {
    const [districtwiselist, setDistrictwiselist] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [districtwiseData, setDistrictwiseData] = useState([]);
    const [selectedNumber, setSelectedNumber] = useState('');

    const [head,sethead]=useState(false)
    const [no,setno]=useState(0)
  

    //--------------------
    useEffect(() => {
      axios.get(`${BaseUrl}/districts`)
        .then((res) => {
          if (res.data) {
            setDistrictwiselist(res.data);
           setno( districtwiseData.flat().length)
          }
        })
  //--------------------deletiled       
    }, []);

useEffect(() => {
  axios.get(`${BaseUrl}/detailedreport?Id=${'00000000-0000-0000-0000-000000000000'}`)
  .then((res) => {
    if (res.data) {
      console.log(res.data)
      setDistrictwiseData(res.data);
      setFilteredData(res.data);
    }
  })
  .catch((error) => {
    console.log(error);
  });
}, [])


    //-------------
    
  ///------------------------------------------------------
    const handleDistrictChange = (selectedOption) => {
        console.log(selectedOption)
      setSelectedDistrict(selectedOption);
      
      axios.get(`${BaseUrl}/detailedreport?Id=${selectedOption.value}&no=${0}`)
        .then((res) => {
          if (res.data) {
            console.log(res.data)
            setDistrictwiseData(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
//search
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const handleSearch = (e) => {
      const searchValue = e.target.value.toLowerCase();
      setSearchQuery(searchValue);
  
      const filteredData = districtwiseData.filter((item) => {
        for (const key in item) {
          const value = item[key];
          if (typeof value === 'string' && value.toLowerCase().includes(searchValue)) {
            return true;
          } else if (typeof value === 'number' && value.toString().includes(searchValue)) {
            return true;
          }
        }
        return false;
      });
      setFilteredData(filteredData);
    };
    const dataToDisplay = searchQuery.length > 0 ? filteredData :districtwiseData;

      const DeleteHandele=(e)=>{
         try {
          // eslint-disable-next-line no-restricted-globals
          const option=confirm("Do you want to Delete")
          console.log(option)
          if(option)
          {
            axios.delete(BaseUrl+`/deletecoupon?number=${e}`)
            .then((e)=>{
              console.log(e)
            },(error)=>{console.log(error)})
       //After deleting loading fulldata
           
          setTimeout(() => {
              axios.get(`${BaseUrl}/detailedreport?Id=${'00000000-0000-0000-0000-000000000000'}`)
              .then((res) => {
                if (res.data) {
                  console.log(res.data)
                  setDistrictwiseData([res.data]);
                }
              })
              .catch((error) => {
                console.log(error);
              });
      
            }, 500)

            .catch((error) => {
              console.log(error);
            });
    
          }else
          {

          }
         
            
         } catch (error) {
          console.log(error)
         }
          
      }

      const handlemodal=()=>{
        try {
          
        } catch (error) {
          console.log(error)
        }
      }



  return (
    
     <div>
    <div style={{float:'left'}}>
    </div>
      {
        selectedDistrict && <h4 style={{textAlign:'center',color:'#3c948b'}}>{selectedDistrict.label}</h4>

      }
        <div style={{ width: '500px', marginBottom: '20px',display:'flex',padding:'20px' }}>
          <Select
            value={selectedDistrict}
            onChange={handleDistrictChange}
            options={districtwiselist.map((district) => ({
              value: district.Id,
              label: district.Name,
              
            }))}
            placeholder="Select District"
          />
        <input
  type="text"
  value={searchQuery}
  onChange={handleSearch}
  placeholder="Search..."
  style={{ marginLeft: '10px', width: '200px' }}
/>

        
        </div>
        
  <div >
  <div style={{ display: 'flex', justifyContent: 'center' }}>
  <table className="table table-hover" style={{ width: '80%' }}>
    <thead className="text-center" style={{background:'#064185',color:'White'}}>
      <tr >     
      <th scope="col" >Slno</th>
      <th scope="col" >District</th>
      {/* <th scope="col">Cluster</th> */}
        <th scope="col">BookNo</th>
        <th scope="col"> IssueDate</th>
        {/* <th>Convener</th> */}
        <th>DealerName</th>
        <th></th>
      </tr>
    </thead>
    {dataToDisplay.map((data, index) => (
        <tbody key={index}>
          <tr style={{textAlign:'center'}}>        
            <td scope="row">{index += 1}</td>
            <td>{data.Name}</td>
            {/* <td>{district.Zone}</td> */}
            <td>{data.Number}</td>
            <td>{data.IssueDate ? data.IssueDate.split('T')[0] : ''}</td>
             {/* <td>{district.Dealer}</td> */}
             <td>{`${data.SubDealerNo} - ${data.Subdealer}`}</td>
             {/* <td>
             <button 
             onClick={()=>DeleteHandele(district.Number)}
             className="issue-button"
             style={{
               color: "black",
               backgroundColor: "white",
               border: "1px solid red",
             }}
             onMouseEnter={(e) => {
               e.target.style.color = "white";
               e.target.style.backgroundColor = "red";
             }}
             onMouseLeave={(e) => {
               e.target.style.color = "black";
               e.target.style.backgroundColor = "white";
             }}
             >Delete</button></td> */}
            
          </tr>
          
        </tbody>
    ))}
    <tfoot>
    <tr>
      <td colSpan="3" style={{fontWeight:'bold'}}>Total Books: {districtwiseData.flat().length
      }</td>
      
    </tr>
  </tfoot>
  </table>
</div>

</div>
      </div>
    
  )
}

export default Detailedreport