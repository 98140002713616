import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { BaseUrl } from '../Constants/BaseUrl'
import '../CSS/statecommitte.css'
import CouponSelector from '../Components/CouponSelector'
import {useNavigate} from 'react-router-dom'
import NotificationModal from '../Components/NotificationModal'
import Datewisedistrictreport from '../Reports/Datewisedistrictreport'
import Detailedreport from '../Reports/Detailedreport'
import Districtwisereport from '../Reports/districtwisereport'
import Getdistrictcountreport from '../Reports/Getdistrictcountreport'
import Select from 'react-select';
import TargetReport from '../Reports/TargetReport'
import DistrictCountPie from '../PieCharts/DistrictCountPie'
import DistrictCountBar from '../PieCharts/DistrictCountBar'
import TargetPie from '../PieCharts/TargetPie'
import TargetBar from '../PieCharts/TargetBar'
import Logo from '../Images/LoginImages/tdaak-logo.png'
function StateCommittePage() {
  const navigate=useNavigate()
  const [DistrictData, setDistrictData] = useState([])
  const [CouponData, setCouponData] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [IsFinished, setIsFinished] = useState(true)
  const toggleIsFinished = () => {
    setIsFinished(!IsFinished);
  };
const [DistrictDetails, setDistrictDetails] = useState({
  Name:'',
  Number:0,
  Id:''
})
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleButtonClick = (Name,Number,DistrictId) => {
    setDistrictDetails({
      Name:Name,
      Number:Number,
      Id:DistrictId
    })
    setShowModal(true);
  };
  useEffect(() => {
    axios.get(BaseUrl+`/drequest`)
    .then(response => {
      // console.log(response.data)
     setDistrictData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
    axios.get(BaseUrl+`/getCoupon`)
    .then(response => {
  
     setCouponData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
  }, [IsFinished]) 
  //Notification
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const handleCloseNotfication = () => {
    setShowNotificationModal(false);
  };

  const handleShowNotfication = () => {
    setShowNotificationModal(true);
  };
  //reports
  const [IsIssueCoupon, setIsIssueCoupon] = useState(true)
  const [IsDetailedReport, setIsDetailedReport] = useState(false)
  const [IsDistrictCountReport, setIsDistrictCountReport] = useState(false)
  const [IsDateWiseReport, setIsDateWiseReport] = useState(false)
  const [IsDistrictWise, setIsDistrictWise] = useState(false)
  const [IsTargetReport, setIsTargetReport] = useState(false)
  const [IsDistrictCountPie, setIsDistrictCountPie] = useState(false);
const [IsDistrictCountBar, setIsDistrictCountBar] = useState(false);
const [IsTargetPie, setIsTargetPie] = useState(false);
const [IsTargetGraph, setIsTargetGraph] = useState(false);
  const handleToggleControl = (val) => {
    if (val === 1) {
      setIsIssueCoupon(true);
      setIsDetailedReport(false);
      setIsDistrictCountReport(false);
      setIsDateWiseReport(false);
      setIsDistrictWise(false);
      setIsTargetReport(false);
      setIsDistrictCountPie(false);
      setIsDistrictCountBar(false);
      setIsTargetPie(false);
      setIsTargetGraph(false);
    } else if (val === 2) {
      setIsIssueCoupon(false);
      setIsDetailedReport(true);
      setIsDistrictCountReport(false);
      setIsDateWiseReport(false);
      setIsDistrictWise(false);
      setIsTargetReport(false);
      setIsDistrictCountPie(false);
      setIsDistrictCountBar(false);
      setIsTargetPie(false);
      setIsTargetGraph(false);
    } else if (val === 3) {
      setIsIssueCoupon(false);
      setIsDetailedReport(false);
      setIsDistrictCountReport(true);
      setIsDateWiseReport(false);
      setIsDistrictWise(false);
      setIsTargetReport(false);
      setIsDistrictCountPie(false);
      setIsDistrictCountBar(false);
      setIsTargetPie(false);
      setIsTargetGraph(false);
    } else if (val === 4) {
      setIsIssueCoupon(false);
      setIsDetailedReport(false);
      setIsDistrictCountReport(false);
      setIsDateWiseReport(true);
      setIsDistrictWise(false);
      setIsTargetReport(false);
      setIsDistrictCountPie(false);
      setIsDistrictCountBar(false);
      setIsTargetPie(false);
      setIsTargetGraph(false);
    } else if (val === 5) {
      setIsIssueCoupon(false);
      setIsDetailedReport(false);
      setIsDistrictCountReport(false);
      setIsDateWiseReport(false);
      setIsDistrictWise(true);
      setIsTargetReport(false);
      setIsDistrictCountPie(false);
      setIsDistrictCountBar(false);
      setIsTargetPie(false);
      setIsTargetGraph(false);
    } else if (val === 6) {
      setIsIssueCoupon(false);
      setIsDetailedReport(false);
      setIsDistrictCountReport(false);
      setIsDateWiseReport(false);
      setIsDistrictWise(false);
      setIsTargetReport(true);
      setIsDistrictCountPie(false);
      setIsDistrictCountBar(false);
      setIsTargetPie(false);
      setIsTargetGraph(false);
    } else if (val === 7) {
      setIsDistrictCountPie(true);
      setIsDistrictCountBar(false);
      setIsTargetPie(false);
      setIsTargetGraph(false);
      setIsIssueCoupon(false);
      setIsDetailedReport(false);
      setIsDistrictCountReport(false);
      setIsDateWiseReport(false);
      setIsDistrictWise(false);
      setIsTargetReport(false);
    } else if (val === 8) {
      setIsDistrictCountPie(false);
      setIsDistrictCountBar(true);
      setIsTargetPie(false);
      setIsTargetGraph(false);
      setIsIssueCoupon(false);
      setIsDetailedReport(false);
      setIsDistrictCountReport(false);
      setIsDateWiseReport(false);
      setIsDistrictWise(false);
      setIsTargetReport(false);
    } else if (val === 9) {
      setIsDistrictCountPie(false);
      setIsDistrictCountBar(false);
      setIsTargetPie(true);
      setIsTargetGraph(false);
      setIsIssueCoupon(false);
      setIsDetailedReport(false);
      setIsDistrictCountReport(false);
      setIsDateWiseReport(false);
      setIsDistrictWise(false);
      setIsTargetReport(false);
    } else if (val === 10) {
      setIsDistrictCountPie(false);
      setIsDistrictCountBar(false);
      setIsTargetPie(false);
      setIsTargetGraph(true);
      setIsIssueCoupon(false);
      setIsDetailedReport(false);
      setIsDistrictCountReport(false);
      setIsDateWiseReport(false);
      setIsDistrictWise(false);
      setIsTargetReport(false);
    }
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: 'reportA', label: 'Detailed Report' },
    { value: 'reportB', label: 'District count Report' },
    { value: 'reportC', label: 'Date wise Report' },
    { value: 'reportD', label: 'District Report' },
    { value: 'reportE', label: 'Target Report' }
    // Add more options for each report component
  ];

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  if (selectedOption && selectedOption.value === 'reportA') {
      handleToggleControl(2);
    } else if (selectedOption && selectedOption.value === 'reportB') {
      handleToggleControl(3);
    } else if (selectedOption && selectedOption.value === 'reportC') {
      handleToggleControl(4);
    } else if (selectedOption && selectedOption.value === 'reportD') {
      handleToggleControl(5);
    } else if (selectedOption && selectedOption.value === 'reportE') {
      handleToggleControl(6);
    }  
  };
  //piecharts
  const [selectedOption2, setSelectedOption2] = useState(null);
  const options2=[
    {value:'ChartA', label:'District Count Piechart'},
    {value:'ChartB', label:'District Count Barchart'},
    {value:'ChartC', label:'Target Piechart'},
    {value:'ChartD', label:'Target Barchart'}
  ]
  const handleChange2 = (selectedOption2) => {
    setSelectedOption2(selectedOption2);
 if (selectedOption2 && selectedOption2.value === 'ChartA') {
      handleToggleControl(7);
    } else if (selectedOption2 && selectedOption2.value === 'ChartB') {
      handleToggleControl(8);
    } else if (selectedOption2 && selectedOption2.value === 'ChartC') {
      handleToggleControl(9);
    } else if (selectedOption2 && selectedOption2.value === 'ChartD') {
      handleToggleControl(10);
    } 
  };
//Notification
const [IsNotifictionFinished, setIsNotifictionFinished] = useState(true)
const toggleIsNotification = () => {
  setIsNotifictionFinished(!IsNotifictionFinished);
};
const [NotificationData, setNotificationData] = useState([])
useEffect(() => {
    axios.get(BaseUrl+`/checkescalations`)
    .then(response => {
      const filteredArray = response.data.filter(obj => obj.Stview === 0);
      console.log(filteredArray);
      setNotificationData(filteredArray)
    })
    .catch(error => {
      console.log(error);
    });
}, [IsNotifictionFinished])
  return (
<>
        <CouponSelector showModal={showModal} onClose={handleModalClose} CouponData={CouponData} DistrictDetails={DistrictDetails} toggleIsFinished={toggleIsFinished}/>
        <NotificationModal showModal={showNotificationModal} handleClose={handleCloseNotfication} NotificationData={NotificationData} toggleIsNotification={toggleIsNotification}/>
<div className="page-header-con order-history" style={{ display: 'flex', alignItems: 'center' }}>
<img src={Logo} alt="Logo" style={{ float: 'left', marginRight: '10px',width:'75px',height:'35px',marginLeft:'10px'}} />
    <h3>Order History</h3>
  </div>
<div className="admin-page-container order-history-container">
    <div className="container">
    <div className="filter-box-wrap">
  <div className="container-fluid">
    <div className="row">
      <div className="col-4">
      <Select
      options={options}
      value={selectedOption}
      onChange={handleChange}
    />
      </div>
      <div className="col-4">
      <Select
      options={options2}
      value={selectedOption2}
      onChange={handleChange2}
    />
      </div>
      <div className="col-2">
    {!IsIssueCoupon&&<h5 style={{ color:'red', cursor:'pointer', textDecoration: 'underline' }} onClick={() => handleToggleControl(1)}>Request new books</h5>}
      </div>
      <div className="col-2">
    {NotificationData.length>0&&<button style={{background:'white'}} onClick={handleShowNotfication}><i className="far fa-bell" style={{ fontSize: '30px', color: 'red' }}></i></button>}
</div>
    </div>
  </div>
</div>
      {IsIssueCoupon&&<div style={{padding:'10px'}}>
        <table className="table table-bordered table-responsive" >
          <thead style={{textAlign:'center'}}>
            <tr >
              <th>District</th>
              <th >No of books Requsted</th>
              <th >Status</th>
            </tr>
          </thead>
          <tbody style={{textAlign:'center'}}>
           {DistrictData.map((data)=>( 
           <tr key={data.DistrictId} style={{ textAlign: 'center', backgroundColor: '#f8f9fa' }}>
              <td>
                <b>{data.DistrictName}</b>
              </td>
              <td>{data.Number}</td>
              <td >
              <button className='issue-button' onClick={() => handleButtonClick(data.DistrictName, data.Number,data.DistrictId)}>Issue</button>
              </td>
            </tr>
            ))}
          </tbody>
        </table>
      </div>}
      {IsDetailedReport&&<Detailedreport/>}
      {IsDistrictCountReport&&<Getdistrictcountreport/>}
      {IsDateWiseReport&&<Datewisedistrictreport/>}
      {IsDistrictWise&&<Districtwisereport/>}
      {IsTargetReport&&<TargetReport/>}
      {IsDistrictCountPie&&<DistrictCountPie/>}
      {IsDistrictCountBar&&<DistrictCountBar/>}
      {IsTargetPie&&<TargetPie/>}
      {IsTargetGraph&&<TargetBar/>}
    </div>
  </div>

</>
  )
}
export default StateCommittePage