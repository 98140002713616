import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';

function DistrictCountPie() {
    const [sdate, setsdate] = useState('');
    const [edate, setedate] = useState('');
    const [data, setdata] = useState([]);
    useEffect(() => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
  
      const startOfMonth = new Date(year, month - 1, 1);
      const endOfMonth = new Date(year, month, 0);
  
      const formattedStartDate = startOfMonth.toISOString().substring(0, 10);
      const formattedEndDate = endOfMonth.toISOString().substring(0, 10);
  
      setsdate(formattedStartDate);
      setedate(formattedEndDate);
      axios
      .get(BaseUrl+ '/getdistrictcount', { params: { sdate: formattedStartDate, edate: formattedEndDate } })
      .then((response) => {
        if (response.data) {
            // console.log("response"+response.data)
          setdata(response.data);
          // console.log("datalist"+data)
          data.map((d)=>{
            // console.log(d)
          })

        }
      })
      .catch((error) => {
        console.log(error);
      });
    }, []);
    const Getdatas=()=>{
        axios
          .get(BaseUrl  + '/getdistrictcount', { params: { sdate: sdate, edate: edate } })
          .then((response) => {
            if (response.data) {
                // console.log("response"+response.data)
              setdata(response.data);
              console.log("datalist"+data)
              data.map((d)=>{
                // console.log(d)
              })
    
            }
          })
          .catch((error) => {
            console.log(error);
          });
        }


        const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#800080']

  // Calculate the total count
  const totalCount = data.reduce((total, entry) => total + entry.TotalCount, 0);

  // Calculate the percentage for each entry
  const dataWithPercentage = data.map((entry) => ({
    ...entry,
    percentage: (entry.TotalCount / totalCount) * 100,
  }));
  const currentDate = new Date().toISOString().split('T')[0];

  return (

  
  <div className="container">
   <span className="glyphicon glyphicon-calendar"> <h5 >TDAAC-Districts Wise Book Count Report</h5></span>
        <div className="row justify-content-center">
       <div></div>
        <div className="col-sm-6 col-md-4">
          <div className="input-group mb-3">
            <input
              type="date"
              className="form-control"
              value={sdate }
              onChange={(e) => setsdate(e.target.value)}
              
            />
            <span className="input-group-addon">
             
            </span>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="input-group mb-3">
            <input
              type="date"
              className="form-control"
              value={edate }
              onChange={(e) => setedate(e.target.value)}
            />
            <span className="input-group-addon">
              <span className="glyphicon glyphicon-calendar"></span>
            </span>
          </div>
        </div>
        <div className="col-md-2">
          <button className="issue-button" onClick={Getdatas} >
            Show
          </button>
        </div>
      </div>
      <div className="row" style={{ marginLeft: '10px' }}>
        <div>
   
        </div>
         <div className="col-lg-6 col-md-12">
    
        <div >
        <PieChart width={400} height={400}>
          <Pie
            data={dataWithPercentage}
            dataKey="TotalCount"
            outerRadius={150}
            label
            startAngle={0}
            endAngle={360}
            animationBegin={200}
            animationDuration={1000}
          >
            {dataWithPercentage.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
            {/*dataWithPercentage.map((entry, index) => (
              <Label
                key={`label-${index}`}
                value={entry.name}
                position={calculateLabelAngle(entry.startAngle, entry.endAngle)}
                style={{ fill: '#fff', fontSize: '14px' }}
              />
            ))*/}
          </Pie>
        </PieChart>
      </div>
    </div>
    <div className="col-lg-6 col-md-12" >
      <div >
        <div className="table-responsive">
         { data.length> 0 && <table className="table">
            <thead>
              <tr>
              <th></th>
              <th>District</th>
                <th>District</th>
                <th>count</th>
              
              </tr>
            </thead>
            <tbody>
              {dataWithPercentage.map((entry, index) => (
                <tr key={`row-${index}`} >
                <td style={{ backgroundColor: colors[index % colors.length] }}></td>
                  <td>{entry.DistrictName}</td>
                  <td>{entry.TotalCount}</td>
                  <td>{entry.percentage.toFixed(2)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
         }
        </div>
      </div>
    </div>
  </div>  
      </div>
  
  )
}

export default DistrictCountPie