import React, { useEffect, useState } from 'react';
import {  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label,Cell } from 'recharts';
import axios from 'axios';
import { BaseUrl } from '../Constants/BaseUrl';
function Bargraphs() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(BaseUrl + `/onekcoupon?num=${'1000'}`)
      .then((res) => {
        setData(res.data);
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Define an array of colors for the regions
  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  const totalCount = data.reduce((total, entry) => total + entry.TotalCount, 0);

  // Calculate the percentage for each entry
  const dataWithPercentage = data.map((entry) => ({
  ...entry,
  percentage: (entry.TotalCount / totalCount) * 100,
  }));
  const currentDate = new Date().toISOString().split('T')[0];
  
  return (
    <div className="row" style={{ marginLeft: '10px' }}>
      <div>
        {/* <h5>TDAAC-Dealer Performance Report</h5> */}
      </div>



      <div className="col-lg-6 col-md-12">
        <div >
        <BarChart width={800} height={400} data={dataWithPercentage}>
              <CartesianGrid strokeDasharray="3 3" barGap={10} barCategoryGap={20} />
              <XAxis dataKey="name">
                <Label value="District" offset={0} position="insideBottom" />
              </XAxis>
              <YAxis>
                <Label value="Count" angle={-90} offset={10} position="insideLeft" />
              </YAxis>
              <Tooltip />
              <Bar dataKey="books" fill="#8884d8" barSize={30}/>
            </BarChart>
        {
          //bar chart
           /* <BarChart width={400} height={400} data={data}>
            <XAxis dataKey="name" />
            <Bar dataKey="books">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Bar>
          </BarChart>
       */ 
       
       }

          
        </div>
      </div>
      {/* <div className="col-lg-6 col-md-12">
        <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Dealer</th>
                  <th>Books</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {data.map((entry, index) => (
                  <tr key={`row-${index}`} style={{ backgroundColor: colors[index % colors.length] }}>
                    <td>{entry.name}</td>
                    <td>{entry.books}</td>
                    <td>{((entry.books / data.reduce((total, e) => total + e.books, 0)) * 100).toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
}


export default Bargraphs