import axios from 'axios';
import React,{useEffect,useState} from 'react'
import { Modal } from 'react-bootstrap'
import { BaseUrl } from '../Constants/BaseUrl';
function MemberCouponCountState(props) {
  const SelectedDistrictId=props.SelectedDistrictData.DistrictId
  const SelectedDistrictName=props.SelectedDistrictData.DistrictName
  const SelectedDistrictCount=props.SelectedDistrictData.TotalCount
  const [Data, setData] = useState([])
  const [CurrentCouponData, setCurrentCouponData] = useState([])
  useEffect(() => {
    axios
    .get(BaseUrl+ `/detailedreportforstate?Id=${SelectedDistrictId}`)
    .then((response) => {
      if (response.data) {
      //  console.log(response.data)
       setData(response.data)
      }
    })
    .catch((error) => {
      console.log(error);
    });
    axios.get(BaseUrl+`/couponsinhand?Id=${SelectedDistrictId}`)
    .then(response => {
      setCurrentCouponData(response.data)
    })
    .catch(error => {
      console.log(error);
    });
  }, [props.SelectedDistrictData.DistrictId]);
   //style
   const styles={
    rowStyles: {
      '--bs-gutter-x': 'initial' // Set the property to 'initial' to remove it
    }
  }
  return (
    <> 
    <Modal show={props.Show} onHide={props.Close} size="lg">
    <Modal.Header closeButton>
      <Modal.Title><h5>{SelectedDistrictName}</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row" style={styles.rowStyles}>
      <div className="col-4 mb-4">
    <div className="Modalcard">
      <span className="big">{SelectedDistrictCount}</span>
      <span className="small">Total Books</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="Modalcard">
      <span className="big" >{CurrentCouponData.length}</span>
      <span className="small" >Book In Hand</span>
    </div>
  </div>
  <div className="col-4 mb-4">
    <div className="Modalcard">
      <span className="big">{SelectedDistrictCount-CurrentCouponData.length}</span>
      <span className="small">Issued Books</span>
    </div>
  </div>
     </div>
    <table className="table table-bordered table-responsive">
  <thead style={{ background: '#064185', color: 'White' }}>
    <tr>
      <th className="text-center">Member</th>
      <th className="text-center">No of Books</th>
    </tr>
  </thead>
  <tbody>
    {
    Data.map((data,index)=>(<tr key={index}>
          <td className="text-center"  >
            {`${data.DealerNo} - ${data.ShopName}`}
          </td>
          <td className="text-center">
            {data.NumberOfCoupons}
          </td>
        </tr>
))}
     </tbody>
     </table>
    </Modal.Body>
  </Modal>
  </>
  )
}

export default MemberCouponCountState