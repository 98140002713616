import React from 'react'
import { BaseUrl } from '../Constants/BaseUrl'
import { useEffect ,useState} from 'react'
import axios from 'axios'
function DistrictCommitment() {
const [data, setdata] = useState([]);
const [Districts, SetDistricts] = useState([])
const [SelectedDistrict,SetSelectedDistrict]=useState('default')

const HandleDistrictSelection=(e)=>{
    try {
       // console.log(e.target.value)
        SetSelectedDistrict(e.target.value)
        axios.get(BaseUrl+`/getdistrictwisecommitment?did=${e.target.value}`)
        .then((response)=>{
            try
            {
               // console.log(response.data)
               setdata(response.data)
        
            }
            catch(ex)
            {
                console.log(ex)
            }
        })
    } catch (error) {
        console.log(error)
    }
}


useEffect(()=>{

    axios.get(`${BaseUrl}/districts`)
    .then((response)=>{
      //console.log(response.data)
         SetDistricts(response.data)
    })


},[])






  return (
    <div>
    <div className='row mb-4'  >
      <div className='col-lg-5 col-6' >
  <select
  onChange={HandleDistrictSelection}
  value={SelectedDistrict}
  >
<option value='default' disabled>Select District</option>
{
    Districts.map((item)=>(
        <option value={item.Id} key={item.Id}>
            {item.Name}
        </option>
    ))
}
  </select>
</div>
</div>
<div className='row my-table-container'>
    <div className="col-12 ">
      <table className="table table-hover">
        <thead style={{background:'#064185',color:'White',textAlign:'center'}}>
          <tr>
            <th>Slno</th>
            <th>Cluster</th>
          <th>Members</th>
          
          <th>40 and 40 Above</th>
          
            <th>20 Commitment</th>
            <th>10 Commitment</th>
            <th>7 Commitment</th>
            <th>7 Below</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td data-label="index">{index+1}</td>
              <td data-label="Cluster">Cluster{item.Cluster}</td>
              <td  data-label="Members">{item.Members}</td>
             
              <td data-label="4000 Commitment">{item.TenThousandCommitment}</td> 
             {/*<td data-label="4000 Commitment">{item.FourThousandCommitment}</td>*/ }
              <td data-label="2000 Commitment">{item.TwoThousandCommitment}</td>
              <td data-label="1000 Commitment">{item.ThousandCommitment}</td>
              <td data-label="700 Below Commitment">{item.ThousandCommitment}</td> 
              <td data-label="700 Below Commitment">{item.SevenHunderdBelow}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
    </div>
  )
}

export default DistrictCommitment