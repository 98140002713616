import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl';

function PaymentHistoryState() {
    const [PaymentHistoryData, setPaymentHistoryData] = useState([])
    const [FilterData,SetFilterData]=useState([])
    const [SearchedData,SetSearchedData]=useState('')
    const currentDate = new Date().toISOString().substr(0, 10);
    const [FromDate, setFromDate] = useState(currentDate)
    const [Todate, setTodate] = useState(currentDate)
    function getStatusText(status,Mode,transactionid) {
        switch (status) {
          case 0:
            return ` ${Mode} payment intimation Pending..`;
          case 2:
            return `${Mode} payment intimation approved`;
          case 1:
            return `${Mode} payment intimation rejected`;
          default:
            return 'Unknown';
        }
      }
      function getStatusColor(status) {
        switch (status) {
          case 0:
            return 'blue';
          case 2:
            return 'green';
          case 1:
            return 'red';
          default:
            return 'black'; // Default color if status is unknown or not found in switch cases
        }
      }

     

      function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        return `${formattedDay}/${formattedMonth}/${year}`;
      }
     useEffect(() => {
      console.log(FromDate)
      console.log(Todate)
      axios.get(`${BaseUrl}/GetPayementHistoryState?FromDate=${FromDate}&ToDate=${Todate}`)
      .then((res) => {
          setPaymentHistoryData(res.data)
          console.log(res.data)
      })
      .catch((error) => console.log(error));
     }, [Todate,FromDate])
   

     function HandleSearch(e)
      {
        SetSearchedData(e.target.value.toLowerCase())
       // console.log(SearchedData)
       const searchvalue=e.target.value.toLowerCase()
       const FilterdData=PaymentHistoryData.filter((item)=>{
        for (const key in item) {
          const value = item[key];
          if (typeof value === 'string' && value.toLowerCase().includes(searchvalue)) {
            return true;
          } else if (typeof value === 'number' && value.toString().includes(searchvalue)) {
            return true;
          }
        }
        return false;
       });
       SetFilterData(FilterdData)

      }

      const dataToDisplay = SearchedData.length > 0 ? FilterData :PaymentHistoryData;

     if (!Array.isArray(PaymentHistoryData)) {
      return (
        <div className="col-lg-12">
          <div className="text-center">
            <h3 style={{ padding: '10px' }}>Payment History</h3>
          </div>
          <p className="text-center">Loading...</p>
        </div>
      );
    }
  return (
    <div className="col-lg-12">
    <div className="text-center">
      <h3 style={{ padding: '10px' }}>Payment History</h3>
    </div>
    <div style={{display:'flex',padding:'10px 10px 10px 10px'}}>
        <input 
         type='text'
         class="form-control"
         placeholder='Search'
         style={{margin:'5px'}} 
         value={SearchedData}
         onChange={HandleSearch}
          /> <input type='date' class="form-control" style={{margin:'5px'}} value={FromDate} onChange={(e) => setFromDate(e.target.value)}/><input type='date' style={{margin:'5px'}} class="form-control"  value={Todate} onChange={(e) => setTodate(e.target.value)}/>
    </div>
    <table className="table table-bordered table-responsive">
      <thead style={{ background: '#064185', color: 'White' }}>
        <tr>
          <th className="text-center">Date</th>
          <th className="text-center">Name</th>
          <th className="text-center">Amount</th>
          <th className="text-center">TransactionId</th>
          <th className="text-center">Status</th>
          <th className="text-center">Remarks</th>
        </tr>
      </thead>
      <tbody>
        {PaymentHistoryData.length === 0 ? (
          <tr>
            <td colSpan="3" className="text-center">
              No data available
            </td>
          </tr>
        ) : (
          dataToDisplay.map((data, index) => (
            <tr key={index}>
              <td className="text-center">{formatDate(data.PaymentDate)}</td>
              <td>{data.Name}</td>
              <td className="text-center" style={{ fontWeight: '700' }}>
                {data.Amount}
              </td>
              <td>{data.TransactionId}</td>
              <td
                className="text-center"
                style={{
                  color: getStatusColor(data.Status),
                  fontWeight: '700',
                }}
              >
                {data.QuantityText}
              </td>
              <td>{data.Remarks=="" ?0:data.Remarks}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  </div>
  )
}

export default PaymentHistoryState